
import {
  defineComponent,
  useStore,
  computed,
  useRoute,
  onBeforeMount,
  ComputedRef
} from '@nuxtjs/composition-api'
import { HomeIcon, ChevronRightIcon } from '~/components/veke-ui/icons'
import { BreadcrumbGetters } from '~/store/breadcrumb'
import { useBreadcrumb } from '~/composable/useBreadcrumb'

export default defineComponent({
  name: 'Breadcrumbs',
  components: { ChevronRightIcon, HomeIcon },
  setup () {
    const store = useStore()
    const route = useRoute()
    const breadcrumb = useBreadcrumb()
    const breadcrumbs: ComputedRef<{value: string, slug: string}[]> = computed(() => store.getters[BreadcrumbGetters.current])
    const crumbs = computed(() => breadcrumbs.value ? breadcrumbs.value.map((crumb, i) => ({ ...crumb, current: i === breadcrumbs.value.length - 1 })) : [])
    onBeforeMount(() => {
      // Breadcrumbs are set null if they are not shown on the page (home, cart, checkout, etc...)
      if (!breadcrumbs.value) { return }
      // Breadcrumbs are empty if not yet generated (e.g. direct navigation, refresh page, etc.)
      if (breadcrumbs.value.length === 0) {
        breadcrumb.setBreadcrumbs(route.value, store)
      }
    })

    return {
      breadcrumbs, crumbs
    }
  }
})
