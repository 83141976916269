import { format, parse, parseISO } from 'date-fns'
import { fi } from 'date-fns/locale'
export function useTime () {
  /**
   * @param date - Date object
   * @param pattern - e.g. 'yyyy-MM-dd', 'eee d.M.yyyy HH:mm'
   */
  const formatDate = (date: Date, pattern = 'd.M.yyyy'): string => format(date, pattern, { locale: fi })

  /**
   * @param dateString
   * @param formatString
   * @param referenceDate
   * @param options
   */
  const parseDate = (dateString: string, formatString = 'd.M.yyyy', referenceDate: Date | number, options = {}) => parse(dateString, formatString, referenceDate, options)

  /**
   * @param string - Datetime in ISO format
   * @param pattern - e.g. 'yyyy-MM-dd', 'eee d.M.yyyy HH:mm'
   * @param fallback - If empty string, fallback to this
   */
  const formatISOString = (string: string, pattern = 'd.M.yyyy', fallback = ''): string => string ? format(parseISO(string), pattern, { locale: fi }) : fallback

  return {
    formatISOString,
    formatDate,
    parseDate
  }
}
