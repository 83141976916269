import { UserGetters } from '~/store/user'

export const mapGtmPageType = (route: any): string => {
  const page = route.path?.split('/') || []

  switch (page.length > 0 ? page[1] : null) {
    case '':
      return 'home'
    case 'category':
      return 'category'
    case 'product':
      return 'product'
    case 'ostoskori':
      return 'cart'
    case 'kassa':
      return 'checkout'
    case 'kiitos':
      return 'orderConfirmation'
    case 'haku':
      return 'searchResult'
    default:
      return 'content'
  }
}

export const getGtmPageTypeDetails = (pageType: string, query: any): any => {
  switch (pageType) {
    case 'category':
      return {
        categorySortType: query?.jarjesta,
        categoryFiltersUsed: getFiltersUsed(query, ['jarjesta']),
        ecommerce: null
      }
    case 'searchResult':
      return {
        searchResults: 'n/a',
        searchKeyword: query?.q,
        searchSortType: query?.jarjesta,
        searchFiltersUsed: getFiltersUsed(query, ['jarjesta', 'q'])
      }
    default:
      return {}
  }
}

export const getFiltersUsed = (query: any, skip: string[]): string => {
  if (!query) { return '' }

  let result = ''
  for (const property in query) {
    if (query[property] && !skip.includes(property)) {
      result += result !== '' ? `&${property}=${query[property]}` : `${property}=${query[property]}`
    }
  }
  return result
}

export const getGtmUserDetails = (store: any) => {
  if (!store) { return { type: 'no_store' } }

  const isUserLoggedIn = store.getters[UserGetters.loggedIn]

  return isUserLoggedIn
    ? {
        userId: store.getters[UserGetters.uid], // magento customer ID
        // lastLoginDate: 'dummy', // magento last logged In (yyyy-mm-dd)
        // daysSinceLastLogin: 'dummy', // calculate dynamically from latest login & previous login
        newsletterSubscriber: store.getters[UserGetters.marketingPermissions], // needs to be discussed does the data live only in Custobar
        // previousPurchases: 'dummy', // magento number of orders
        customerName: `${store.getters[UserGetters.firstname]} ${store.getters[UserGetters.lastname]}`, // magento customer information first name + last name
        customerEmail: store.getters[UserGetters.email] // magento customer information email
        // userGroup: 'dummy' // magento Customer Group
      }
    : { type: 'not_logged_in' }
}
