// eslint-disable-next-line import/named
import { ActionTree, GetterTree, MutationTree, Commit } from 'vuex'
import { RootState } from '~/store'
import type { PageMessageItem, PageMessageLocation } from '~/types/contentful/page-message'

export const state = () => ({
  pageMessages: [],
  fetching: false
})

export type PageMessageState = ReturnType<typeof state>

export enum PageMessageActions {
  fetchPageMessages = 'pageMessage/fetchPageMessages'
}

export enum PageMessageMutations {
  setPageMessages = 'pageMessage/SET_PAGE_MESSAGES'
}

export enum PageMessageGetters {
  getPageMessagesByLocation = 'pageMessage/getPageMessagesByLocation'
}

export const actions: ActionTree<PageMessageState, RootState> = {
  async fetchPageMessages ({ state, commit }: { state: PageMessageState, getters: any, commit: Commit }) {
    if (state.pageMessages.length > 0 || state.fetching) { return }
    commit('SET_FETCHING', true)
    // @ts-ignore
    const pageMessages = await this.$contentDb.getPageMessages()
    if (pageMessages.length > 0) {
      commit('SET_PAGE_MESSAGES', pageMessages)
    }
    commit('SET_FETCHING', false)
  }
}

export const mutations: MutationTree<PageMessageState> = {
  SET_PAGE_MESSAGES (state: any, payload: PageMessageItem[]) {
    state.pageMessages = payload
  },
  SET_FETCHING (state: any, payload: boolean) {
    state.fetching = payload
  }
}

export const getters: GetterTree<PageMessageState, RootState> = {
  getPageMessagesByLocation: (state: any) => (location: PageMessageLocation): PageMessageItem[] => state.pageMessages.filter((msg: PageMessageItem) => msg.locations.includes(location)).sort((a: PageMessageItem) => a.widget ? -1 : 1)
}
