import Vue from 'vue'
import { INLINES } from '@contentful/rich-text-types'
import { renderLink } from '~/helpers/contentful'

export default Vue.extend({
  computed: {
    defaultNodeRenderers (): {[key:string]: any } {
      return ({
        [INLINES.HYPERLINK]: (node: any, key: string, h: any, next: any) => renderLink(node, key, h, next)
      })
    }
  },
  methods: {
    hasContent (richText: { json: any }) {
      return richText?.json?.content[0]?.content?.some((c: any) => c.value !== '')
    }
  }
})
