
import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ReviewStars',
  props: {
    score: {
      type: Number,
      required: false,
      default: 0,
      validator: (score: number) => score >= 0 && score <= 5
    },
    animate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const reviewScore = computed(() => parseFloat((props.score).toFixed(1)))
    const width = computed(() => Math.ceil((reviewScore.value / 5) * 100) + '%')
    const title = computed(() => props.score ? `Arvosana ${reviewScore.value} / 5` : 'Ei arvosteluja.')
    return { reviewScore, width, title }
  }
})
