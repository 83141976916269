import mitt, { Emitter } from 'mitt'
import { Plugin } from '@nuxt/types'
import type { EmitterEvents } from '~/types/emitter'

const plugin: Plugin = (_, inject) => {
  const emitter: Emitter<EmitterEvents> = mitt<EmitterEvents>()
  inject('emitter', emitter)
}

declare module 'vue/types/vue' {
  interface Vue {
    $emitter: Emitter<EmitterEvents>
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $emitter: Emitter<EmitterEvents>
  }
  interface Context {
    $emitter: Emitter<EmitterEvents>
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $emitter: S
  }
}

export default plugin
