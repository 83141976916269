
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import CustomerServiceInfo from '~/components/site-footer/CustomerServiceInfo.vue'
import SocialMediaLinks from '~/components/site-footer/SocialMediaLinks.vue'

export default {
  name: 'BaseFooter',
  components: {
    RichTextRenderer, SocialMediaLinks
  },
  props: {
    content: {
      type: Object,
      required: true,
      default: () => ({})
    },
    forVekeFi: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    showPaymentMethods () {
      return this.forVekeFi && this.paymentMethods
    },
    paymentMethods () {
      return this.content?.paymentMethods
    },
    hasSlot () {
      return !!this.$slots.default
    },
    customerService () {
      return this.content?.customerService
    },
    stores () {
      return this.content?.stores
    },
    conditions () {
      return this.content?.conditions
    },
    veke () {
      return this.content?.veke
    },
    socialMedia () {
      return this.content?.socialMediaCollection?.items
    }
  },
  methods: {
    generateUrl (link, key, h) {
      const vekeFiBaseUrl = 'https://www.veke.fi'
      if (this.forVekeFi && link.url.startsWith((vekeFiBaseUrl))) {
        const route = link.url.replace(vekeFiBaseUrl, '')
        return h('nuxt-link', { key, attrs: { to: route } }, link.name)
      } else {
        return h('a', { key, attrs: { href: link.url, target: '_blank' } }, link.name)
      }
    },
    renderEntries (contentField) {
      const entryMap = new Map()
      const blocks = contentField.links?.entries?.block || []
      const inlines = contentField.links?.entries?.inline || []
      for (const entry of blocks) {
        entryMap.set(entry.sys.id, entry)
      }
      for (const entry of inlines) {
        entryMap.set(entry.sys.id, entry)
      }
      return {
        [BLOCKS.EMBEDDED_ENTRY]: (node, key, h) => {
          const entry = entryMap.get(node.data.target.sys.id)
          return h(CustomerServiceInfo, { key, props: { content: { fields: entry } } }, null)
        },
        [INLINES.EMBEDDED_ENTRY]: (node, key, h) => {
          const entry = entryMap.get(node.data.target.sys.id)
          return this.generateUrl({ name: entry.name, url: entry.urlPath }, key, h)
        },
        [BLOCKS.HEADING_2]: (node, key, h, next) => {
          return h('div', { key, attrs: { class: 'footer-heading' } }, next(node.content, key, next))
        },
        [INLINES.HYPERLINK]: (node, key, h) => {
          return this.generateUrl({ name: node.content[0].value, url: node.data.uri }, key, h)
        }
      }
    }
  }
}
