import { onMounted, onUnmounted, ref, watch, useRoute } from '@nuxtjs/composition-api'
// @ts-ignore
import throttle from 'lodash.throttle'
export function useScrollHeader () {
  // Disable scroll listeners when viewport width is above this (px). Only checked on mount!
  const breakpoint = 768
  // Pixels from the top of page, when scroll header cannot be hidden
  const topOffset = 119
  // Timespan (ms) when scroll handler can be called max once (actually twice, at start and at the end)
  const throttleAmount = 333
  // -----
  const route = useRoute()
  const lastPosition = ref(0)
  const isVisible = ref(true)

  const handleScroll = throttle(function () {
    if (topOffset >= window.scrollY) {
      isVisible.value = true
    } else if (lastPosition.value < window.scrollY) {
      isVisible.value = false
    } else if (lastPosition.value > window.scrollY) {
      isVisible.value = true
    }

    lastPosition.value = window.scrollY
  }, throttleAmount, { leading: true, trailing: true })

  /**
   * Reset scroll header when route changes
   */
  const routeWatcher = watch(() => route.value.path, () => {
    isVisible.value = true
    handleScroll.cancel()
  })

  onMounted(() => {
    if (window.innerWidth < breakpoint) {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('popstate', handleScroll.cancel)
    } else {
      routeWatcher() // Turn off watcher
    }
  })
  onUnmounted(() => {
    if (window.innerWidth < breakpoint) {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('popstate', handleScroll.cancel)
    }
  })

  return {
    isVisible
  }
}
