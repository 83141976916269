
export default {
  layout: 'default',
  props: {
    error: {
      type: Object,
      default: () => ({ statusCode: 500 })
    }
  }
}
