
export default {
  name: 'Countdown',
  props: {
    end: {
      type: String,
      required: true,
      default: ''
    }
  },
  emits: ['countdown-end'],
  data () {
    return {
      now: null
    }
  },
  computed: {
    countdown () {
      const now = this.now
      const end = new Date(this.end).getTime()
      const distance = end - now
      const h = this.addLeadingZero(Math.floor((distance) / (1000 * 60 * 60)))
      const m = this.addLeadingZero(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)))
      const s = this.addLeadingZero(Math.floor((distance % (1000 * 60)) / 1000))
      if (!now) {
        return ''
      }
      if (distance <= 0) {
        this.$emit('countdown-end')
        return ''
      } else {
        return h + ':' + m + ':' + s
      }
    }
  },
  mounted () {
    this.now = Date.now()
    const self = this
    setInterval(function () {
      self.now = Date.now()
    }, 1000)
  },
  methods: {
    addLeadingZero (n) {
      return (n < 10) ? '0' + n : n
    }
  }
}
