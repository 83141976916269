
import Vue from 'vue'
import BaseButton from '~/components/BaseButton.vue'
import { ArrowLeftIcon, GridIcon } from '~/components/veke-ui/icons'
import MenuItem from '~/components/site-header/mobile-menu/MenuItem.vue'

export default Vue.extend({
  name: 'SubMenu',
  components: {
    BaseButton,
    MenuItem,
    ArrowLeftIcon,
    GridIcon
  },
  props: {
    item: {
      type: Array,
      required: true
    },
    transition: {
      type: String,
      required: false,
      default: 'fade'
    }
  },
  emits: ['menu-item-opened', 'menu-back'],
  computed: {
    current () {
      return this.item[this.item.length - 1]
    },
    previous () {
      return this.item[this.item.length - 2]
    },
    textSize () {
      return this.current ? this.current.value.length > 28 ? 'text-14' : this.current.value.length > 24 ? 'text-16' : '' : ''
    }
  },
  methods: {
    openMenuItem (category) {
      this.$emit('menu-item-opened', category)
    },
    back () {
      this.$emit('menu-back')
    }
  }
})
