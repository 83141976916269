import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import type { State, Toast } from '~/types/toast'
import { RootState } from '~/store'

export const state = () => ({
  toasts: []
})

export type ToastState = State

export enum ToastMutations {
  addToast = 'toast/ADD_TOAST',
  removeToast = 'toast/REMOVE_TOAST',
  clearToasts = 'toast/CLEAR_TOASTS'
}

export enum ToastGetters {
  toasts = 'toast/toasts',
}
export enum ToastActions {
  addToast = 'toast/addToast',
  removeToast = 'toast/removeToast',
  clearToasts = 'toast/clearToasts',
}

export const actions: ActionTree<ToastState, RootState> = {
  addToast ({ commit }, toast: Toast) {
    commit('ADD_TOAST', toast)
  },
  removeToast ({ commit }, id: string) {
    commit('REMOVE_TOAST', id)
  },
  clearToasts ({ commit }) {
    commit('CLEAR_TOASTS')
  }
}
export const mutations: MutationTree<ToastState> = {
  ADD_TOAST (state: State, payload: Toast) {
    state.toasts.push({ id: uuidv4(), ...payload })
  },
  REMOVE_TOAST (state: State, id: string) {
    state.toasts = state.toasts.filter((t: Toast) => t.id !== id)
  },
  CLEAR_TOASTS (state: State) {
    state.toasts = []
  }
}

export const getters: GetterTree<ToastState, RootState> = {
  toasts: (state: State): Toast[] => state.toasts
}
