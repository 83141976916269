
import { SearchIcon } from '~/components/veke-ui/icons'
import BaseInput from '~/components/BaseInput.vue'

export default {
  name: 'SearchField',
  components: { BaseInput, SearchIcon },
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  }
}
