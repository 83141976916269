
import Vue from 'vue'
import ArrowLink from '~/components/site-header/mobile-menu/ArrowLink.vue'

export default Vue.extend({
  name: 'MegaSubMenu',
  components: { ArrowLink },
  props: {
    openedId: {
      type: String,
      required: false,
      default: ''
    },
    menu: {
      type: Object,
      required: true
    }
  },
  computed: {
    categoryImage () {
      return this.menu?.image || 'category_huonekalut.webp'
    }
  }
})
