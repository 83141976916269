
import Vue from 'vue'
import MegaSubMenu from '~/components/site-header/mega-menu/MegaSubMenu.vue'
import { CategoryGetters } from '~/store/category'

export default Vue.extend({
  name: 'MegaMenu',
  components: {
    MegaSubMenu
  },
  data () {
    return {
      openedMenuId: '',
      openMenuClassActive: false,
      onceOpenedMenuIds: new Set()
    }
  },
  computed: {
    routePath () {
      return this.$route.path
    },
    categoryTree () {
      return this.$store.getters[CategoryGetters.getCategoryTree]
    }
  },
  watch: {
    routePath () {
      this.toggleSubMenu()
    }
  },
  methods: {
    toggleSubMenu (id) {
      this.onceOpenedMenuIds.add(id)
      this.openedMenuId = (this.openedMenuId === id) ? '' : id
      setTimeout(() => { this.openMenuClassActive = !!this.openedMenuId }, 300)
    },
    onceOpened (id) {
      return this.onceOpenedMenuIds.has(id)
    },
    isOpen (id) {
      return this.openedMenuId === id
    }
  }
})
