/*
 * Render a hyperlink node as a nuxt-link or anchor tag, depending on the URL.
 * - Paths -> Render nuxt-link
 * - Veke.fi URLs -> Turn to path and render nuxt-link
 * - Other URLs -> Render an anchor link that opens in a new tab
 */
export const renderLink = (node: any, key: string, h: any, next: any) => {
  const link = { name: node.content[0].value, url: node.data.uri }
  const vekeFiBaseUrl = 'https://www.veke.fi'
  if (link.url.startsWith('/')) {
    return h('nuxt-link', { key, attrs: { to: link.url } }, next(node.content, key, next))
  } else if (link.url.startsWith((vekeFiBaseUrl))) {
    const route = link.url.replace(vekeFiBaseUrl, '')
    return h('nuxt-link', { key, attrs: { to: route } }, next(node.content, key, next))
  } else {
    return h('a', { key, attrs: { href: link.url, target: '_blank' } }, next(node.content, key, next))
  }
}
