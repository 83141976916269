import { GetterTree, ActionTree, MutationTree, Commit } from 'vuex'
import { RootState } from '~/store'
import type { ICampaignItem } from '~/types/contentful/campaign'

interface CampaignState {
  umbrellaCampaign: ICampaignItem | null
  isFetching: boolean
}

export const state = (): CampaignState => ({
  umbrellaCampaign: null,
  isFetching: false
})

export enum CampaignActions {
  fetchActiveUmbrellaCampaign = 'campaign/fetchActiveUmbrellaCampaign',
}
export enum CampaignMutations {}
export enum CampaignGetters {
  getUmbrellaCampaign = 'campaign/getUmbrellaCampaign',
  getSubCampaignTileBanners = 'campaign/getSubCampaignTileBanners',
  getSubCampaignBySlug = 'campaign/getSubCampaignBySlug'
}

export const actions: ActionTree<CampaignState, RootState> = {
  async fetchActiveUmbrellaCampaign ({ state, commit }: { state: CampaignState, commit: Commit }) {
    if (state.umbrellaCampaign || state.isFetching) { return }

    commit('SET_IS_FETCHING', true)
    // @ts-ignore
    await this.$contentDb.getUmbrellaCampaign().then((campaign: any) => {
      commit('SET_UMBRELLA_CAMPAIGN', campaign)
    }).finally(() => {
      commit('SET_IS_FETCHING', false)
    })
  }
}
export const mutations: MutationTree<CampaignState> = {
  SET_UMBRELLA_CAMPAIGN (state: CampaignState, payload: any) {
    state.umbrellaCampaign = payload
  },
  SET_IS_FETCHING (state: CampaignState, payload: boolean) {
    state.isFetching = payload
  }
}
export const getters: GetterTree<CampaignState, RootState> = {
  getUmbrellaCampaign: (state: CampaignState) => state.umbrellaCampaign,
  getSubCampaignBySlug: (state: CampaignState) => (slug: string): ICampaignItem|null => state.umbrellaCampaign?.subCampaignsCollection?.items?.filter(Boolean).find((c: ICampaignItem) => c.slug === slug) || null
}
