import { onMounted, onUnmounted, useStore } from '@nuxtjs/composition-api'
import type { NuxtFireInstance } from '@nuxtjs/firebase'
import type { DocumentSnapshot } from '@firebase/firestore-types'
import type {
  MarketingPermissions,
  UserAddress,
  UserDetails,
  UserDetailsBase,
  UserOrder,
  Wishlist
} from '~/types/user'
import type { WishlistItem } from '~/types/item'
import { UserActions } from '~/store/user'

export function useReauth ($fire: NuxtFireInstance, $fireModule: any) {
  const reauthenticateUser = async (psw: string): Promise<boolean> => {
    if (!$fire.auth.currentUser || $fire.auth.currentUser?.email === null) {
      $fire.auth.signOut()
      return false
    }

    // https://github.com/nuxt-community/firebase-module/issues/20
    const creds = $fireModule.auth.EmailAuthProvider.credential($fire.auth.currentUser.email, psw)
    return await $fire.auth.currentUser.reauthenticateWithCredential(creds)
      .then(() => true)
      .catch((err: any) => {
        console.error('reauthenticateWithCredential', err)
        return false
      })
  }

  return { reauthenticateUser }
}
export function useAuth ($fire: NuxtFireInstance) {
  const checkAuthStatus = () => {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve: any) => {
      let triesLeft = 5
      const interval = setInterval(() => {
        if ($fire.auth.currentUser) {
          resolve($fire.auth.currentUser)
        }
        if (--triesLeft === 0) {
          clearInterval(interval)
          resolve(null)
        }
      }, 500)
    })
  }

  const createUserWithEmailAndPassword = async (email: string, psw: string) => await $fire.auth.createUserWithEmailAndPassword(email, psw)
  const signInWithEmailAndPassword = async (email: string, psw: string) => await $fire.auth.signInWithEmailAndPassword(email, psw)
  const reloadUser = async (): Promise<void> => ((!$fire.auth.currentUser) ? Promise.resolve() : await $fire.auth.currentUser.reload())

  // const reauthenticateUser = async (psw: string): Promise<boolean> => {
  //   if (!$fire.auth.currentUser || $fire.auth.currentUser?.email === null) {
  //     $fire.auth.signOut()
  //     return false
  //   }

  //   // NOTE: EmailAuthProvider and reauthenticateWithCredential are not available on $fire
  //   // NOTE2: They should be though: https://github.com/nuxt-community/firebase-module/issues/20
  //   const creds = $fireModule.auth.EmailAuthProvider.credential($fire.auth.currentUser.email, psw)
  //   return await $fire.auth.currentUser.reauthenticateWithCredential(creds)
  //   // @TODO: Make sure signInWithEmailAndPassword achieves the same as reauthenticateWithCredential - do we need to sign out first?
  //   // return await signInWithEmailAndPassword($fire.auth.currentUser.email, psw)
  //     .then(() => true)
  //     .catch((err: any) => {
  //       console.error('reauthenticateWithCredential', err)
  //       return false
  //     })
  // }

  const verifyBeforeUpdateEmail = async (email: string): Promise<boolean> => {
    if ($fire.auth.currentUser) {
      return await $fire.auth.currentUser?.verifyBeforeUpdateEmail(email)
        .then(() => {
          // Update email to store and on the Firestore-document
          // const store = useStore()
          // const { updateUserDocument } = useFirestore($fire)
          // const details = store.getters[UserGetters.details]
          // details.email = email
          // store.dispatch(UserActions.setUserDetails, details)
          // await updateUserDocument(details)

          return true
        })
        .catch((err: any) => {
          console.error('updateAuthEmail', err)
          return false
        })
    } else {
      return false
    }
  }

  const updatePassword = async (newPsw: string): Promise<boolean> => {
    if ($fire.auth.currentUser) {
      return await $fire.auth.currentUser?.updatePassword(newPsw)
        .then(() => true)
        .catch((err: any) => {
          console.error('updatePassword', err)
          return false
        })
    } else {
      return false
    }
  }

  const sendPasswordResetEmail = async (email: string) => await $fire.auth.sendPasswordResetEmail(email)
  const confirmPasswordReset = async (oobCode: string, psw: string) => await $fire.auth.confirmPasswordReset(oobCode, psw)
  const verifyPasswordResetCode = async (oobCode: string) => await $fire.auth.verifyPasswordResetCode(oobCode)

  const handleApplyActionCode = async (actionCode: any): Promise<boolean> => {
    if (!actionCode) {
      return false
    }
    return await $fire.auth.applyActionCode(actionCode).then(() => {
      return true
    // eslint-disable-next-line node/handle-callback-err
    }).catch((err: any) => {
      // Sentry.captureException(err)
      console.error(err)
      return false
    })
  }

  const getCurrentUser = () => $fire.auth.currentUser

  const signOutUser = () => $fire.auth.signOut()

  return {
    checkAuthStatus,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    reloadUser,
    // reauthenticateUser,
    verifyBeforeUpdateEmail,
    updatePassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    verifyPasswordResetCode,
    handleApplyActionCode,
    getCurrentUser,
    signOutUser
  }
}

export function useFirestore ($fire: NuxtFireInstance) {
  // @TODO: should this also add a dummy (empty) wishlist --> addWishlist(dummyWishlist)
  const createUserDocument = async (uid: string, userDetails: UserDetailsBase): Promise<void> => await $fire.firestore.collection('users').doc(uid).set(userDetails)

  const updateUserDocument = async (userDetails: UserDetails): Promise<boolean> => {
    return await $fire.firestore.collection('users').doc($fire.auth.currentUser?.uid).update(userDetails)
      .then(() => true)
      .catch((err: any) => {
        console.error('User document update failed', err)
        return false
      })
  }

  const addAddress = async (address: UserAddress): Promise<any> => {
    return await $fire.firestore.collection(`users/${$fire.auth.currentUser?.uid}/addresses`).add(address)
      .catch((err: any) => {
        console.error('addAddress', err)
        return null
      })
  }

  const updateAddress = async (address: UserAddress): Promise<boolean> => {
    return await $fire.firestore.collection(`users/${$fire.auth.currentUser?.uid}/addresses`).doc(address.id || undefined).update(address)
      .then(() => true)
      .catch((err: any) => {
        console.error('updateAddress', err)
        return false
      })
  }

  const removeAddress = async (id: string): Promise<boolean> => {
    return await $fire.firestore.collection(`users/${$fire.auth.currentUser?.uid}/addresses`).doc(id).delete()
      .then(() => true)
      .catch((err: any) => {
        console.error('removeAddress', err)
        return false
      })
  }

  const addWishlist = async (name?: string): Promise<Wishlist | null> => {
    if (!$fire.auth.currentUser?.uid) { console.error('User not found!'); return Promise.reject(null) }
    const createdAt = new Date()
    const docRef = await $fire.firestore.collection(`users/${$fire.auth.currentUser?.uid}/wishlists`).doc()
    if (docRef.id === undefined) { console.error('Firestore document ID not found!'); return Promise.reject(null) }
    const wishlist: Wishlist = {
      id: docRef.id,
      name: name || 'Toivelista',
      items: [],
      createdAt: createdAt.toISOString(),
      updatedAt: createdAt.toISOString()
    }
    return docRef.set(wishlist)
      .then(() => Promise.resolve(wishlist))
      .catch((err) => {
        console.error(err)
        return Promise.reject(null)
      })
  }

  const deleteWishlist = async (wishlistId: string): Promise<void> => {
    return await $fire.firestore.collection(`users/${$fire.auth.currentUser?.uid}/wishlists`).doc(wishlistId).delete()
      .catch(() => {
        console.error(`Deleting wishlist with ID '${wishlistId}' failed!`)
        return Promise.reject()
      })
  }

  const updateWishlist = async (wishlist: Wishlist): Promise<void> => {
    const croppedWishlist = { ...wishlist }
    // @TODO: 13.3.2024: Temporary code - removes productObject from wishlist items before sending it to Firestore. The productObject may still exist on user's browser.
    croppedWishlist.items = croppedWishlist.items.map((item: WishlistItem) => {
      const itemClone = { ...item }
      delete itemClone.productObject
      return itemClone
    })
    // <-- Temp code ends
    const updatedAt = new Date()
    croppedWishlist.updatedAt = updatedAt.toISOString()

    return await $fire.firestore.collection(`users/${$fire.auth.currentUser?.uid}/wishlists`).doc(croppedWishlist.id || undefined).update(croppedWishlist)
      .catch(() => {
        console.error(`Updating wishlist '${croppedWishlist.name}' failed!`)
        return Promise.reject()
      })
  }

  const updateShareCart = async (shareCart: any, id: string): Promise<boolean> => {
    return await $fire.firestore.collection('veke3000-share-cart').doc(id || undefined).update(shareCart)
      .then(() => true)
      .catch((err: any) => {
        console.error('updateShareCart', err)
        return false
      })
  }

  const getUserDetailsWithSubcollections = async (): Promise<UserDetails | null> => {
    if ($fire.auth.currentUser) {
      const getUser = $fire.functions.httpsCallable('veke3000-customer-getUser')
      return await getUser()
        .then((response: any) => {
          return response.data.response
        })
        .catch((err) => {
          // Sentry.captureException(err)
          console.error(err)
          return null
        })
    }
    return null
  }

  const getUserOrders = async (): Promise<UserOrder[]> => {
    if ($fire.auth.currentUser) {
      const getOrders = $fire.functions.httpsCallable('veke3000-customer-getOrders')
      return await getOrders()
        .then((response: any) => {
          return response.data
        })
        .catch((err) => {
          // Sentry.captureException(err)
          console.error(err)
          return []
        })
    }
    return []
  }

  const updateUserMarketingPermissions = async (marketingPermissions: MarketingPermissions) => {
    return await $fire.firestore.collection('users').doc($fire.auth.currentUser?.uid).update({ marketingPermissions })
      .then(() => true)
      .catch((err: any) => {
        console.error(err)
        return false
      })
  }

  return {
    createUserDocument,
    updateUserDocument,
    addAddress,
    updateAddress,
    removeAddress,
    getUserDetailsWithSubcollections,
    getUserOrders,
    updateUserMarketingPermissions,
    addWishlist,
    deleteWishlist,
    updateWishlist,
    updateShareCart
  }
}

let userDetailListener: (() => void) | null = null

/**
 * Starts listening changes in user document in Firestore when Vue component is mounted.
 * When the document changes, updated user details are saved to Vuex state.
 * Listening is stopped when the component is unmounted.
 */
export default function useFirestoreListener ($fire: NuxtFireInstance) {
  /**
   * Attaches a listener for DocumentSnapshot events in user document.
   * When the document in Firestore is changed, update Vuex state with the updated user details
   */
  const listen = () => {
    if (userDetailListener || !$fire.auth.currentUser) {
      return
    }
    // @ts-ignore
    const { uid } = $fire.auth.currentUser
    const store = useStore()
    const { getUserDetailsWithSubcollections } = useFirestore($fire)

    userDetailListener = $fire.firestore.collection('users').doc(uid).onSnapshot(async (_doc: DocumentSnapshot) => {
      const userDetails = await getUserDetailsWithSubcollections()
      if (!userDetails) { return }
      store.dispatch(UserActions.setUserDetails, userDetails)
    })
  }
  /**
   * Removes the listener for DocumentSnapshot events in user document.
   */
  const unlisten = () => {
    if (!userDetailListener) {
      return
    }
    userDetailListener()
    userDetailListener = null
  }

  onMounted(() => listen())
  onUnmounted(() => unlisten())
}
