import { Plugin } from '@nuxt/types'

const plugin: Plugin = ({ $axios, $config }, inject) => {
  const config = {
    headers: {
      accept: 'application/json ',
      authorization: `Bearer ${$config.CONTENTFUL_TOKEN}`
    }
  }
  inject('contentful', async (query: object): Promise<any> => {
    return await $axios.$post(`${$config.CONTENTFUL_GRAPHQL_API_URL}/${$config.CONTENTFUL_SPACE_ID}/environments/${$config.CONTENTFUL_ENVIRONMENT}`, query, config)
      .then((res: any) => res.data)
      .catch(() => {
        // @TODO: Add Sentry logging
        return undefined
      })
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $contentful(query: object): any
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $contentful(query: object): any
  }
  interface Context {
    $contentful(query: object): any
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $contentful(query: object): S
  }
}

export default plugin
