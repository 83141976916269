export const eur = (val: any) => {
  if (!val && val !== 0) {
    return '- €'
  }
  if (val === 0) {
    return '0 €'
  }
  if (typeof val === 'string' && val.includes('.')) {
    return parseFloat(val).toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' }).replace(/,00/g, '')
  } else if (typeof val === 'number') {
    return val.toLocaleString('fi-FI', { style: 'currency', currency: 'EUR' }).replace(/,00/g, '')
  } else {
    return val + ' €'
  }
}
