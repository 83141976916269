
import Vue from 'vue'
import { UserGetters } from '~/store/user'
import ToastNotifications from '~/components/toast-notifications/ToastNotifications.vue'
import SkipToContent from '~/components/SkipToContent.vue'

export default Vue.extend({
  name: 'CheckoutLayout',
  components: { ToastNotifications, SkipToContent },
  computed: {
    isLoggedIn () {
      return this.$store.getters[UserGetters.loggedIn]
    }
  }
})
