
import Vue from 'vue'
import { ArrowRightIcon } from '~/components/veke-ui/icons'
import type { CategoryItem } from '~/types/category'

export default Vue.extend({
  name: 'MenuItem',
  components: {
    ArrowRightIcon
  },
  props: {
    item: {
      type: Object as () => CategoryItem,
      required: true
    }
  },
  computed: {
    isDiscountCategory () {
      return this.item?.tags?.includes('discounts-only') || false
    }
  }
})
