
import Vue from 'vue'
import BaseButton from '~/components/BaseButton.vue'
import { ToastActions } from '~/store/toast'
import type { Toast } from '~/types/toast'

export default Vue.extend({
  name: 'Notification',
  components: { BaseButton },
  props: {
    toast: {
      type: Object as () => Toast,
      required: true
    }
  },
  mounted () {
    if (this.toast.ttl && this.toast.ttl > 0) {
      setTimeout(() => { this.remove() }, this.toast.ttl)
    }
  },
  methods: {
    remove () {
      this.$store.dispatch(ToastActions.removeToast, this.toast.id)
    },
    removeAll () {
      this.$store.dispatch(ToastActions.clearToasts)
    }
  }
})
