// eslint-disable-next-line import/named
import { GetterTree, MutationTree } from 'vuex'
import { RootState } from '~/store'

export const state = () => ({
  loginModalState: false,
  authUserCart: false
})

export type ModalState = ReturnType<typeof state>

export enum ModalMutations {
  loginState = 'modal/SET_LOGIN_STATE',
  authUserCart = 'modal/SET_AUTH_USER_CART_STATE',
}

export enum ModalGetters {
  login = 'modal/login',
  authUserCart = 'modal/authUserCart',
}

export const mutations: MutationTree<ModalState> = {
  SET_LOGIN_STATE (state: any, payload: boolean) {
    state.loginModalState = payload
  },
  SET_AUTH_USER_CART_STATE (state: any, payload: boolean) {
    state.authUserCart = payload
  }
}

export const getters: GetterTree<ModalState, RootState> = {
  login: (state: any): boolean => state.loginModalState,
  authUserCart: (state: any): boolean => state.authUserCart
}
