import insightsClient from 'search-insights'
import { Plugin } from '@nuxt/types'

declare module 'vue/types/vue' {
  interface Vue {
    $algoliaInsights: any
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $algoliaInsights: any
  }
  interface Context {
    $algoliaInsights: any
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $algoliaInsights: S
  }
}

const algoliaInsights: Plugin = (context, inject) => {
  insightsClient('init', {
    appId: context.$config.ALGOLIA_APP_ID,
    apiKey: context.$config.ALGOLIA_API_KEY
  })
  inject('algoliaInsights', insightsClient)
}

export default algoliaInsights
