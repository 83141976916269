
import Vue from 'vue'
import BaseFooter from '~/components/site-footer/BaseFooter.vue'

export default Vue.extend({
  name: 'SiteFooter',
  components: {
    BaseFooter
  },
  data () {
    return {
      footerContent: {}
    }
  },
  async fetch () {
    this.footerContent = await this.$contentDb.getFooter()
  }
})
