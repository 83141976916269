import algoliasearch from 'algoliasearch'
import { Plugin } from '@nuxt/types'
import { createNullCache } from '@algolia/cache-common'

interface AlgoliaSearchOptions {
  appId: string,
  apiKey: string,
  index: string,
  cache: boolean
}
interface OptionalAlgoliaSearchOptions extends Partial<AlgoliaSearchOptions> {}

declare module 'vue/types/vue' {
  interface Vue {
    $algolia: (options?: OptionalAlgoliaSearchOptions) => any
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $algolia: any
  }
  interface Context {
    $algolia: any
  }
}

declare module 'vuex/types/index' {
  interface Store<S> {
    $algolia: any
  }
}

const algolia: Plugin = (context, inject) => {
  const defaultOptions: AlgoliaSearchOptions = {
    appId: context.$config.ALGOLIA_APP_ID,
    apiKey: context.$config.ALGOLIA_API_KEY,
    index: context.$config.ALGOLIA_INDEX,
    cache: true
  }

  inject('algolia', (options?: OptionalAlgoliaSearchOptions) => {
    const opt: AlgoliaSearchOptions = { ...defaultOptions, ...options }
    const cacheOptions = !opt.cache ? { requestsCache: createNullCache(), responsesCache: createNullCache() } : undefined
    const client = algoliasearch(opt.appId, opt.apiKey, cacheOptions)
    return client.initIndex(opt.index)
  })
}

export default algolia
