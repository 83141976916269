// eslint-disable-next-line import/named
import { ActionTree, GetterTree, MutationTree, Commit } from 'vuex'
import { RootState } from '~/store'
import type { IBannerItem } from '~/types/contentful/banner'

export const state = () => ({
  banners: []
})

export type BannerState = ReturnType<typeof state>

export enum BannerActions {
  fetchBannerBySlug = 'banner/fetchBannerBySlug',
  fetchBannersBySlugs = 'banner/fetchBannersBySlugs'
}

export enum BannerMutations {
  setBanners = 'banner/SET_BANNERS',
  addBanner = 'banner/ADD_BANNER'
}

export enum BannerGetters {
  getBanners = 'banner/getBanners',
  getBannerBySlug = 'banner/getBannerBySlug',
  getBannersBySlugs = 'banner/getBannersBySlugs',
}

export const actions: ActionTree<BannerState, RootState> = {
  async fetchBannerBySlug ({ state, getters, commit }: { state: BannerState, getters: any, commit: Commit }, slug: string) {
    if (getters.getBannerBySlug(slug)) { return }
    // @ts-ignore
    const banner = await this.$contentDb.getBannerBySlug(slug)
    if (banner) {
      commit('ADD_BANNER', banner)
    }
  },
  async fetchBannersBySlugs ({ state, getters, commit }: { state: BannerState, getters: any, commit: Commit }, slugs: string[]) {
    // Skip fetch, if all slugs are already fetched
    if (slugs.every(slug => state.banners.find((b: IBannerItem) => b.slug === slug))) { return }
    // @ts-ignore
    const banners = await this.$contentDb.getBannersBySlugs(slugs)
    if (banners) {
      for (const banner of banners) {
        if (!getters.getBannerBySlug(banner.slug)) {
          commit('ADD_BANNER', banner)
        }
      }
    }
  }
}

export const mutations: MutationTree<BannerState> = {
  SET_BANNERS (state: any, payload: IBannerItem[]) {
    state.banners = payload
  },
  ADD_BANNER (state: any, payload: IBannerItem) {
    state.banners.push(payload)
  }
}

export const getters: GetterTree<BannerState, RootState> = {
  getBanners: (state: any): IBannerItem => state.banners,
  getBannerBySlug: (state: any) => (slug: string): IBannerItem => state.banners.find((b: IBannerItem) => b.slug === slug),
  getBannersBySlugs: (state: any) => (slugs: string[]): IBannerItem => state.banners.filter((b: IBannerItem) => b.slug && slugs.includes(b.slug)).sort((a: IBannerItem, b: IBannerItem) => slugs.indexOf(a.slug || '') - slugs.indexOf(b.slug || ''))
}
