import CheckIcon from './lib/CheckIcon.vue'
import CreditCardIcon from './lib/CreditCardIcon.vue'
import HashIcon from './lib/HashIcon.vue'
import PackageIcon from './lib/PackageIcon.vue'
import PackageCheckIcon from './lib/PackageCheckIcon.vue'
import PackagePlusIcon from './lib/PackagePlusIcon.vue'
import PackageMinusIcon from './lib/PackageMinusIcon.vue'
import TruckIcon from './lib/TruckIcon.vue'
import ShipIcon from './lib/ShipIcon.vue'
import BellOffIcon from './lib/BellOffIcon.vue'
import XIcon from './lib/XIcon.vue'
import CartIcon from './lib/CartIcon.vue'
import MailIcon from './lib/MailIcon.vue'
import PhoneIcon from './lib/PhoneIcon.vue'
import StarIcon from './lib/StarIcon.vue'
import StarHalfIcon from './lib/StarHalfIcon.vue'
import HeartIcon from './lib/HeartIcon.vue'
import EllipsisLoadingIcon from './lib/EllipsisLoadingIcon.vue'
import ChevronLeftIcon from './lib/ChevronLeftIcon.vue'
import ChevronRightIcon from './lib/ChevronRightIcon.vue'
import ClockIcon from './lib/ClockIcon.vue'
import InstagramIcon from './lib/InstagramIcon.vue'
import FacebookIcon from './lib/FacebookIcon.vue'
import YoutubeIcon from './lib/YoutubeIcon.vue'
import PinterestIcon from './lib/PinterestIcon.vue'
import ArrowUpIcon from './lib/ArrowUpIcon.vue'
import ArrowDownIcon from './lib/ArrowDownIcon.vue'
import ArrowLeftIcon from './lib/ArrowLeftIcon.vue'
import ArrowRightIcon from './lib/ArrowRightIcon.vue'
import ExternalLinkIcon from './lib/ExternalLinkIcon.vue'
import PlusIcon from './lib/PlusIcon.vue'
import PlusCircleIcon from './lib/PlusCircleIcon.vue'
import XCircleIcon from './lib/XCircleIcon.vue'
import MessageSquareIcon from './lib/MessageSquareIcon.vue'
import AlertCircleIcon from './lib/AlertCircleIcon.vue'
import ZoomInIcon from './lib/ZoomInIcon.vue'
import ZoomOutIcon from './lib/ZoomOutIcon.vue'
import CalendarIcon from './lib/CalendarIcon.vue'
import MenuIcon from './lib/MenuIcon.vue'
import SearchIcon from './lib/SearchIcon.vue'
import ChevronUpIcon from './lib/ChevronUpIcon.vue'
import ChevronDownIcon from './lib/ChevronDownIcon.vue'
import UserIcon from './lib/UserIcon.vue'
import AwardIcon from './lib/AwardIcon.vue'
import SlidersIcon from './lib/SlidersIcon.vue'
import HomeIcon from './lib/HomeIcon.vue'
import RefreshIcon from './lib/RefreshIcon.vue'
import InfoIcon from './lib/InfoIcon.vue'
import SettingsIcon from './lib/SettingsIcon.vue'
import TagIcon from './lib/TagIcon.vue'
import ThumbsUpIcon from './lib/ThumbsUpIcon.vue'
import ThumbsDownIcon from './lib/ThumbsDownIcon.vue'
import TrashIcon from './lib/TrashIcon.vue'
import KeyIcon from './lib/KeyIcon.vue'
import EyeIcon from './lib/EyeIcon.vue'
import EyeOffIcon from './lib/EyeOffIcon.vue'
import MinusIcon from './lib/MinusIcon.vue'
import MinusCircleIcon from './lib/MinusCircleIcon.vue'
import MapPinIcon from './lib/MapPinIcon.vue'
import EditIcon from './lib/EditIcon.vue'
import FlagIcon from './lib/FlagIcon.vue'
import GridIcon from './lib/GridIcon.vue'
import AtSignIcon from './lib/AtSignIcon.vue'
import SlashIcon from './lib/SlashIcon.vue'
import MinimizeIcon from './lib/MinimizeIcon.vue'
import MaximizeIcon from './lib/MaximizeIcon.vue'
import ImageIcon from './lib/ImageIcon.vue'
import CopyIcon from './lib/CopyIcon.vue'
import BoxIcon from './lib/BoxIcon.vue'
import Rotate3dIcon from './lib/Rotate3dIcon.vue'
import CheckCircleIcon from './lib/CheckCircleIcon.vue'
import XFilledIcon from './lib/XFilledIcon.vue'
import ClockFilledIcon from './lib/ClockFilledIcon.vue'
import EuroIcon from './lib/EuroIcon.vue'
import RulerIcon from './lib/RulerIcon.vue'
import PaperclipIcon from './lib/PaperclipIcon.vue'
import ArmchairIcon from './lib/ArmchairIcon.vue'
import MilieuIcon from './lib/MilieuIcon.vue'
import NonMilieuIcon from './lib/NonMilieuIcon.vue'
import ShareIcon from './lib/ShareIcon.vue'
import BadgePercentIcon from './lib/BadgePercentIcon.vue'
import PercentCircleIcon from './lib/PercentCircleIcon.vue'
import PercentSquareIcon from './lib/PercentSquareIcon.vue'
import PercentIcon from './lib/PercentIcon.vue'
import PawIcon from './lib/PawIcon.vue'
import CameraIcon from './lib/CameraIcon.vue'
import SadFaceIcon from './lib/SadFaceIcon.vue'
import FrownFaceIcon from './lib/FrownFaceIcon.vue'
import SmileyFaceIcon from './lib/SmileyFaceIcon.vue'
import HappyFaceIcon from './lib/HappyFaceIcon.vue'
import StoreIcon from './lib/StoreIcon.vue'
import MoreVerticalIcon from './lib/MoreVerticalIcon.vue'
import ListXIcon from './lib/ListXIcon.vue'
import FileIcon from './lib/FileIcon.vue'
import CircleDotIcon from './lib/CircleDotIcon.vue'
import WrenchIcon from './lib/WrenchIcon.vue'
import RowsFourIcon from './lib/RowsFourIcon.vue'
import CheckFilledIcon from './lib/CheckFilledIcon.vue'

export default null // This is only for disabling warning about missing default export
export {
  CheckIcon,
  CreditCardIcon,
  BellOffIcon,
  HashIcon,
  TruckIcon,
  ShipIcon,
  PackageIcon,
  PackageCheckIcon,
  PackagePlusIcon,
  PackageMinusIcon,
  ClockFilledIcon,
  XFilledIcon,
  XIcon,
  CartIcon,
  MailIcon,
  PhoneIcon,
  StarIcon,
  StarHalfIcon,
  HeartIcon,
  EllipsisLoadingIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  InstagramIcon,
  FacebookIcon,
  PinterestIcon,
  YoutubeIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ExternalLinkIcon,
  PlusIcon,
  PlusCircleIcon,
  XCircleIcon,
  MessageSquareIcon,
  AlertCircleIcon,
  ZoomInIcon,
  ZoomOutIcon,
  CalendarIcon,
  MenuIcon,
  SearchIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  UserIcon,
  AwardIcon,
  HomeIcon,
  InfoIcon,
  RefreshIcon,
  SlidersIcon,
  SettingsIcon,
  TagIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
  TrashIcon,
  KeyIcon,
  EyeIcon,
  EyeOffIcon,
  MinusIcon,
  MinusCircleIcon,
  MapPinIcon,
  EditIcon,
  FlagIcon,
  GridIcon,
  AtSignIcon,
  SlashIcon,
  MinimizeIcon,
  MaximizeIcon,
  ImageIcon,
  CopyIcon,
  BoxIcon,
  Rotate3dIcon,
  CheckCircleIcon,
  CheckFilledIcon,
  EuroIcon,
  RulerIcon,
  PaperclipIcon,
  ArmchairIcon,
  MilieuIcon,
  NonMilieuIcon,
  ShareIcon,
  BadgePercentIcon,
  PercentCircleIcon,
  PercentSquareIcon,
  PercentIcon,
  PawIcon,
  CameraIcon,
  SadFaceIcon,
  FrownFaceIcon,
  HappyFaceIcon,
  SmileyFaceIcon,
  StoreIcon,
  MoreVerticalIcon,
  ListXIcon,
  FileIcon,
  CircleDotIcon,
  WrenchIcon,
  RowsFourIcon
}
