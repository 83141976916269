import { CategoryGetters } from '~/store/category'
import type { ProductAlgolia, AttributeValueObject, GtmProduct, ProductCategory } from '~/types/product'
import type { Breadcrumb } from '~/types/breadcrumb'
import type { CartItem } from '~/types/item'
import type { CategoryItem } from '~/types/category'
import type { MatomoEcommView, MatomoItem } from '~/modules/matomo/types'

export function useProduct () {
  const convertCartItemsToMatomoItems = (items: CartItem[]): MatomoItem [] => {
    return items.map((item: CartItem) => {
      return {
        sku: item.sku,
        name: item.variationName,
        categories: item.categories,
        price: item.price.final,
        amount: item.amount
      }
    })
  }

  const mapMatomoProductViewData = (product: ProductAlgolia, breadcrumbs: Breadcrumb[]): MatomoEcommView => {
    return {
      sku: product.sku,
      name: product.name?.variation,
      categories: breadcrumbs.map(cat => cat.value).join(' / '),
      price: product.customization?.finalPrice || product.finalPrice?.inclTax
    }
  }

  const convertProductToGtmProduct = (product: ProductAlgolia, store: any): GtmProduct | null => {
    if (!product) { return null }
    const gtmProduct = {
      name: product.name.default, // OR product.name.variant
      id: product.parentSku, // parent SKU
      variant: product.sku, // child SKU
      brand: getBrandList(product.marketing.productManufacturer), // Product brand list
      price: product.finalPrice.inclTax, // final price, after discounts
      category: getCategoryPath(product.categories, store).map(cat => cat.value).join('/'),
      stock_status: product.stock, // 'status=in_stock,verkkokauppa=0,rovaniemi=1,vantaa=12,oulu=4,delivery_time=30,delivery_cost=19.90', // stock_status
      campaign_info: product.price.inclTax !== product.finalPrice.inclTax ? { on_sale: true, original_price: product.price.inclTax, campaign_code: product.finalPrice.ruleId } : undefined, // 'on_sale=true,original_price=22.00,campaign_code=KESÄ20', // campaign_info
      rating_info: product.yotpo // 'rating_count=10,rating_value=3.5,question_count=3,ugc_post_count=3' // product_ratings & ugc_posts
    }

    return gtmProduct
  }

  const convertCartItemToGtmProduct = (cartItem: CartItem): GtmProduct | null => {
    if (!cartItem) { return null }

    const gtmProduct = {
      name: cartItem.title,
      id: cartItem.parentSku,
      variant: cartItem.sku,
      brand: getBrandList(cartItem.brands),
      price: cartItem.price.final,
      quantity: cartItem.amount,
      category: cartItem.categories,
      stock_status: cartItem.stock,
      campaign_info: cartItem.price.original !== cartItem.price.final ? { on_sale: true, original_price: cartItem.price.original, campaign_code: cartItem.price.priceRuleId } : undefined,
      rating_info: cartItem.reviews
    }

    return gtmProduct
  }

  const getBrandList = (productManufacturer: AttributeValueObject | undefined): string => {
    if (typeof productManufacturer?.value === 'object') {
      return productManufacturer?.value.map((brand: AttributeValueObject) => brand.label).join(', ')
    }
    return ''
  }

  const getCategoryPath = (categories: ProductCategory[], store: any): Breadcrumb[] => {
    const result: Breadcrumb[] = []
    let currLvl: number = 0
    let currParentId: string = ''

    for (let i = 0; i < categories.length; i++) {
      const cat = categories[i]

      if (cat.level === currLvl + 1) {
        // Root category
        if (cat.level === 1) {
          if (cat.id === '14' || cat.id === '2964') {
            // skip 'Ideat' and 'Feikki kategoriat'
            continue
          }

          const categoryItem: CategoryItem = store.getters[CategoryGetters.getCategoryById](cat.id)
          if (categoryItem && categoryItem.path) {
            currLvl = cat.level
            currParentId = cat.id
            // @TODO: Add Sentry logging
            result.push({ slug: categoryItem.path, value: cat.value, id: cat.id })
            continue
          }
        }

        // Children
        if (cat.parentId === currParentId) {
          const parent = categories.find(c => c.id === currParentId)

          if (parent) {
            const categoryItem: CategoryItem = store.getters[CategoryGetters.getCategoryById](cat.id)
            if (categoryItem && categoryItem.path) {
              currLvl = cat.level
              currParentId = cat.id
              // @TODO: Add Sentry logging
              result.push({ slug: categoryItem.path, value: cat.value, id: cat.id })
            }
          }
        }
      }
    }

    return result
  }

  const getAvailableAmount = (product: ProductAlgolia, amount: number): number => {
    // Discontinued product max buy amount
    if (product.productLine === 2) {
      return amount > product.stock.qty ? product.stock.qty : amount
    }

    return amount
  }

  return {
    convertCartItemsToMatomoItems,
    mapMatomoProductViewData,
    convertProductToGtmProduct,
    convertCartItemToGtmProduct,
    getBrandList,
    getCategoryPath,
    getAvailableAmount
  }
}
