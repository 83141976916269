import { render, staticRenderFns } from "./ToastNotification.vue?vue&type=template&id=4591115d&scoped=true"
import script from "./ToastNotification.vue?vue&type=script&lang=ts"
export * from "./ToastNotification.vue?vue&type=script&lang=ts"
import style0 from "./ToastNotification.vue?vue&type=style&index=0&id=4591115d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4591115d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/src/nuxt-app/components/BaseButton.vue').default})
