import { Commit, Dispatch, GetterTree, ActionTree, MutationTree } from 'vuex'
import { RootState } from '~/store'
import { attributesForProductTileMini } from '~/helpers/instantsearch'
import { calculateCustomizationIdentifier, recalculateCustomizationPrice } from '~/helpers/customization'
import type { ProductAlgolia, ProductImage, ReviewImage, ProductTileMiniAlgolia } from '~/types/product'
import type { Customization, CustomSize, CustomUpholstery, CustomizationWithIdentifier, CustomizationWithPrice } from '~/types/customization'

export enum ProductActions {
  openProduct = 'product/openProduct',
  fetchCurrentProductVariations = 'product/fetchCurrentProductVariations',
  fetchCurrentProductBySlug = 'product/fetchCurrentProductBySlug',
  removeCurrentProduct = 'product/removeCurrentProduct',
  removeCurrentProductVariations = 'product/removeCurrentProductVariations',
  setCurrentProduct = 'product/setCurrentProduct',
  setLightBoxSlideNumber = 'product/setLightBoxSlideNumber',
  fetchRelatedProducts = 'product/fetchRelatedProducts',
  setCustomSize = 'product/setCustomSize',
  setCustomUpholstery = 'product/setCustomUpholstery',
  setCustomSizeValidationError = 'product/setCustomSizeValidationError',
  setCustomUpholsteryValidationError = 'product/setCustomUpholsteryValidationError',
  removeCustomProperty = 'product/removeCustomProperty',
  resetCustomization = 'product/resetCustomization'
}

export enum ProductMutations {
  setCurrentProduct = 'product/SET_CURRENT_PRODUCT'
}

export enum ProductGetters {
  currentProduct = 'product/currentProduct',
  currentProductVariations = 'product/currentProductVariations',
  currentProductRelated = 'product/currentProductRelated',
  currentProductImages = 'product/currentProductImages',
  currentProductReviewImages = 'product/currentProductReviewImages',
  lightBoxInitSlideNumber = 'product/lightBoxInitSlideNumber',
  customSize = 'product/customSize',
  customUpholstery = 'product/customUpholstery',
  customSizeError = 'product/customSizeError',
  customUpholsteryError = 'product/customUpholsteryError',
  hasCustomizationError = 'product/hasCustomizationError',
  currentProductMainVariation = 'product/currentProductMainVariation',
  customization = 'product/customization',
  variationsOnDisplay = 'product/variationsOnDisplay'
}

export const state = () => ({
  currentProduct: null as ProductAlgolia | null,
  currentProductVariations: [] as ProductAlgolia[],
  currentProductRelated: [] as ProductTileMiniAlgolia[],
  lightBoxInitSlideNumber: 0,
  customization: {
    properties: {}
  } as Customization,
  customPropertyValidationErrors: [] as string[]
})

export type ProductState = ReturnType<typeof state>

export const actions: ActionTree<ProductState, RootState> = {
  async fetchCurrentProductVariations ({ state, commit }: { state: ProductState, commit: Commit }) {
    if (state.currentProduct && !state.currentProductVariations.some(v => v.sku === state.currentProduct?.sku)) {
      commit('SET_CURRENT_PRODUCT_VARIATIONS', [])
      await this.$algolia().search('', { filters: `parentSku:'${state.currentProduct.parentSku}'`, attributesToHighlight: [], hitsPerPage: 1000 }).then(({ hits }: { hits: ProductAlgolia[] }) => {
        commit('SET_CURRENT_PRODUCT_VARIATIONS', hits)
      })
    }
  },

  async fetchRelatedProducts ({ commit }: { state: ProductState, commit: Commit }, payload: string[]) {
    if (payload.length === 0) {
      commit('SET_CURRENT_PRODUCT_RELATED', [])
      return
    }

    const query = '(' + payload.map(id => 'id:' + id).join(' OR ') + ') AND isMainVariation:true AND NOT stock.stockStatus:openOutOfStock AND NOT stock.stockStatus:outOfStock'

    await this.$algolia().search('', { filters: query, attributesToHighlight: [], attributesToRetrieve: attributesForProductTileMini, hitsPerPage: 6 }).then(({ hits }: { hits: ProductAlgolia[] }) => {
      commit('SET_CURRENT_PRODUCT_RELATED', hits)
    })
  },
  fetchCurrentProductBySlug ({ commit }: { commit: Commit }, slug: string) {
    return this.$algolia().search('', { filters: `slug:'${slug}'`, attributesToHighlight: [], hitsPerPage: 1 }).then(({ hits }: { hits: ProductAlgolia[] }) => { commit('SET_CURRENT_PRODUCT', hits[0]); return hits[0] })
  },
  async openProduct ({ dispatch }: { dispatch: Dispatch }, payload: { slug: string, product: ProductAlgolia }) {
    if (payload.product) {
      await dispatch('setCurrentProduct', payload.product)
    }
    const slug = payload.slug || payload.product?.slug
    if (slug) {
      this.$router.push('/product/' + slug)
    }
  },
  setLightBoxSlideNumber ({ commit }, payload: number) {
    commit('SET_CURRENT_LIGHT_BOX_SLIDE_NUMBER', payload)
  },
  removeCurrentProduct ({ commit }) {
    commit('SET_CURRENT_PRODUCT', null)
  },
  removeCurrentProductVariations ({ commit }) {
    commit('SET_CURRENT_PRODUCT_VARIATIONS', [])
  },
  setCurrentProduct ({ commit }: { commit: Commit }, product: ProductAlgolia) {
    commit('SET_CURRENT_PRODUCT', product)
  },
  setLightBoxStatus ({ commit }: { commit: Commit }, payload: boolean) {
    commit('SET_LIGHT_BOX_STATUS', payload)
  },
  setPixmoorModalStatus ({ commit }: { commit: Commit }, payload: boolean) {
    commit('SET_PIXMOOR_MODAL_STATUS', payload)
  },
  setVideoModalStatus ({ commit }: { commit: Commit }, payload: boolean) {
    commit('SET_VIDE_MODAL_STATUS', payload)
  },
  setCustomSize ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, payload: CustomSize) {
    commit('SET_CUSTOM_SIZE', payload)
    dispatch('setCurrentProductCustomization')
  },
  setCustomUpholstery ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, payload: CustomUpholstery) {
    commit('SET_CUSTOM_UPHOLSTERY', payload)
    dispatch('setCurrentProductCustomization')
  },
  setCustomSizeValidationError ({ commit }: { commit: Commit }, payload: boolean) {
    commit('SET_CUSTOM_SIZE_ERROR', payload)
  },
  setCustomUpholsteryValidationError ({ commit }: { commit: Commit }, payload: boolean) {
    commit('SET_CUSTOM_UPHOLSTERY_ERROR', payload)
  },
  setCurrentProductCustomization ({ state, commit }: { state: ProductState, commit: Commit }) {
    if (state.currentProduct) {
      const customization: CustomizationWithIdentifier = { ...state.customization, identifier: calculateCustomizationIdentifier(state.currentProduct.sku, state.customization) }
      const customizationWithPrice: CustomizationWithPrice = recalculateCustomizationPrice(state.currentProduct.finalPrice.inclTax, state.currentProduct.price.inclTax, customization)

      commit('SET_CURRENT_PRODUCT_CUSTOMIZATION', customizationWithPrice)
    }
  },
  removeCustomProperty ({ commit }: { commit: Commit }, payload: string) {
    commit('REMOVE_CUSTOM_PROPERTY', payload)
  },
  resetCustomization ({ commit }: { commit: Commit }) {
    commit('RESET_CUSTOMIZATION')
  }
}

export const mutations: MutationTree<ProductState> = {
  SET_CURRENT_PRODUCT (state: ProductState, payload: ProductAlgolia) {
    state.currentProduct = payload
  },
  SET_CURRENT_PRODUCT_VARIATIONS (state: ProductState, payload: ProductAlgolia[]) {
    state.currentProductVariations = payload
  },
  SET_CURRENT_PRODUCT_RELATED (state: any, payload: ProductAlgolia[]) {
    state.currentProductRelated = payload
  },
  SET_CURRENT_LIGHT_BOX_SLIDE_NUMBER (state: ProductState, payload: number) {
    state.lightBoxInitSlideNumber = payload
  },
  SET_CUSTOM_SIZE (state: ProductState, payload: CustomSize) {
    const customProperties = { ...state.customization.properties }
    customProperties.size = payload
    state.customization = { ...state.customization, properties: customProperties }
  },
  SET_CUSTOM_UPHOLSTERY (state: ProductState, payload: CustomUpholstery) {
    const customProperties = { ...state.customization.properties }
    customProperties.upholstery = payload
    state.customization = { ...state.customization, properties: customProperties }
  },
  SET_CUSTOM_SIZE_ERROR (state: ProductState, payload: boolean) {
    if (payload) {
      if (!state.customPropertyValidationErrors.includes('size')) {
        state.customPropertyValidationErrors.push('size')
      }
    } else {
      state.customPropertyValidationErrors = state.customPropertyValidationErrors.filter(item => item !== 'size')
    }
  },
  SET_CUSTOM_UPHOLSTERY_ERROR (state: ProductState, payload: boolean) {
    if (payload) {
      if (!state.customPropertyValidationErrors.includes('upholstery')) {
        state.customPropertyValidationErrors.push('upholstery')
      }
    } else {
      state.customPropertyValidationErrors = state.customPropertyValidationErrors.filter(item => item !== 'upholstery')
    }
  },
  SET_CURRENT_PRODUCT_CUSTOMIZATION (state: ProductState, payload: CustomizationWithPrice) {
    if (state.currentProduct) {
      state.currentProduct = { ...state.currentProduct, customization: payload }
    }
  },
  REMOVE_CUSTOM_PROPERTY (state: ProductState, payload: string) {
    const properties: any = { ...state.customization.properties }
    delete properties[payload]
    state.customization = { ...state.customization, properties }
  },
  RESET_CUSTOMIZATION (state: ProductState) {
    state.customization = { properties: {} }
    delete state.currentProduct?.customization
  }
}

export const getters: GetterTree<ProductState, RootState> = {
  currentProduct: (state: ProductState): object | null => state.currentProduct,
  currentProductVariations: (state: ProductState): object[] | [] => state.currentProductVariations,
  currentProductRelated: (state: ProductState): ProductTileMiniAlgolia[] | [] => state.currentProductRelated,
  lightBoxInitSlideNumber: (state: ProductState): number => state.lightBoxInitSlideNumber,
  currentProductImages: (state: ProductState): ProductImage[] | undefined => state.currentProduct?.images,
  currentProductReviewImages: (state: ProductState): ReviewImage[] | undefined => state.currentProduct?.reviewImages,
  customization: (state: ProductState): Customization | undefined => state.customization,
  customSize: (state: ProductState): CustomSize | undefined => state.customization?.properties?.size,
  customUpholstery: (state: ProductState): CustomUpholstery | undefined => state.customization?.properties?.upholstery,
  hasCustomizationError: (state: ProductState): boolean => state.customPropertyValidationErrors.length > 0,
  customSizeError: (state: ProductState): boolean => state.customPropertyValidationErrors.includes('size'),
  customUpholsteryError: (state: ProductState): boolean => state.customPropertyValidationErrors.includes('upholstery'),
  currentProductMainVariation: (state: ProductState): ProductAlgolia | undefined => state.currentProductVariations.find((cpv: ProductAlgolia) => cpv.isMainVariation),
  variationsOnDisplay: (state: ProductState) => (storeCode: string): ProductAlgolia[] => state.currentProductVariations.filter((cpv: ProductAlgolia) => cpv.stock.storeAvailability[storeCode] && cpv.stock.storeAvailability[storeCode].onDisplayStock > 0)
}
