
import Vue from 'vue'
import { ArrowRightIcon, ExternalLinkIcon } from '~/components/veke-ui/icons'

export default Vue.extend({
  name: 'ArrowLink',
  components: {
    ArrowRightIcon,
    ExternalLinkIcon
  },
  computed: {
    linkElement () {
      return this.$attrs.href ? 'a' : this.$attrs.to ? 'nuxt-link' : 'div'
    },
    icon () {
      return this.$attrs.href ? 'external-link-icon' : 'arrow-right-icon'
    }
  }
})
