import createPersistedState from 'vuex-persistedstate'
import { Store } from 'vuex'
import { RootState } from '~/store'

export default ({ store }: ({ store: Store<RootState> })) => {
  createPersistedState({
    key: 'user-session',
    paths: ['user', 'cart.items', 'cart.createdAt']
  })(store)
}
