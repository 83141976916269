import { UserActions } from '~/store/user'
import { useAuth } from '~/composable/useFirebase'

export default async function ({ app, next, store }: { app: any, next: any, store: any }) {
  if (process.client) {
    if (!app.$fire.auth.currentUser) {
      const { checkAuthStatus } = useAuth(app.$fire)
      const currentUser: any = await checkAuthStatus()
      if (!currentUser) {
        // if there is no current user, be sure that the state will log out user also
        store.dispatch(UserActions.setLoggedInStatus, false)
        return next('/kirjaudu')
      }
    }

    if (!app.$fire.auth.currentUser?.emailVerified) {
      return next('/vahvista-sahkopostiosoitteesi')
    }
  }
}
