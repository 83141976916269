
import Vue from 'vue'
import SiteHeader from '~/components/site-header/SiteHeader.vue'
import SiteFooter from '~/components/site-footer/SiteFooter.vue'
import BenefitBar from '~/components/site-header/BenefitBar.vue'
import SiteMenu from '~/components/site-header/mobile-menu/SiteMenu.vue'
import Breadcrumbs from '~/components/Breadcrumbs.vue'
import GlobalMessage from '~/components/global-message/GlobalMessage.vue'
import SiteReviews from '~/components/SiteReviews.vue'
import ToastNotifications from '~/components/toast-notifications/ToastNotifications.vue'
import SkipToContent from '~/components/SkipToContent.vue'

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    SkipToContent,
    GlobalMessage,
    SiteHeader,
    BenefitBar,
    SiteFooter,
    SiteMenu,
    SiteReviews,
    Breadcrumbs,
    ToastNotifications
  },
  head (): any {
    return {
      titleTemplate: (t: string) => {
        return `${t} - veke.fi`
      }
    }
  }
})
