import VueRouter from 'vue-router'
import type { AisRouteState } from '~/types/instantsearch'

export const attributesForProductTile = ['slug', 'name', 'yotpo', 'stock', 'finalPrice', 'labels', 'colorVariations', 'defaultImage', 'defaultVariationImage', 'price', 'images', 'erpinfo', 'variationPrices', 'labelTags']
export const attributesForProductTileMini = ['slug', 'name', 'stock', 'finalPrice', 'defaultImage', 'defaultVariationImage', 'price', 'erpinfo', 'variationPrices', 'labels']

export const nuxtRouter = (vueRouter: VueRouter) => {
  return {
    read (): any {
      return vueRouter.currentRoute.query
    },
    write (routeState: AisRouteState) {
      if (this.createURL(routeState) === this.createURL(this.read())) { return }
      vueRouter.push({ query: routeState })
    },
    createURL (routeState: AisRouteState) {
      return vueRouter.resolve({ query: routeState }).href
    },
    onUpdate () { },
    dispose () { }
  }
}
