

import { defineComponent, useStore, useRouter, computed, useContext } from '@nuxtjs/composition-api'
import BaseButton from '~/components/BaseButton.vue'
import SearchPanel from '~/components/search/SearchPanel.vue'
import { CartIcon, UserIcon, MenuIcon, HeartIcon } from '~/components/veke-ui/icons'
import { UserGetters, UserActions } from '~/store/user'
import { CartGetters } from '~/store/cart'
import MegaMenu from '~/components/site-header/mega-menu/MegaMenu.vue'
import { UiActions } from '~/store/ui'

export default defineComponent({
  name: 'SiteHeader',
  components: {
    CartIcon,
    UserIcon,
    MenuIcon,
    HeartIcon,
    BaseButton,
    MegaMenu,
    SearchPanel
  },
  setup () {
    const { $fire } = useContext()
    const store = useStore()
    const router = useRouter()
    const isLoggedIn = computed(() => store.getters[UserGetters.loggedIn])
    const initials = computed(() => store.getters[UserGetters.initials])
    const cartItemAmount = computed(() => store.getters[CartGetters.totalItemAmount])
    const wishlistItemAmount = computed(() => store.getters[UserGetters.totalWishlistItemAmount] || 0)
    const toggleMenu = () => { store.dispatch(UiActions.toggleMainMenu) }
    const logOut = () => {
      $fire.auth.signOut()
        .then(() => {
          store.dispatch(UserActions.setLoggedInStatus, false)
          store.dispatch(UserActions.setUserDetails, null)
          store.dispatch(UserActions.onAuthStateChanged, { authUser: null }) // fireAuth.onAuthStateChanged should handle this?
          router.push('/')
        })
    }
    const routeToAccount = (accountPath: string) => { const path = !isLoggedIn.value ? '/kirjaudu' : accountPath; router.push(path) }
    return {
      isLoggedIn,
      initials,
      cartItemAmount,
      wishlistItemAmount,
      toggleMenu,
      routeToAccount,
      logOut
    }
  }
})
