
import { InstagramIcon, PinterestIcon, YoutubeIcon, FacebookIcon } from '~/components/veke-ui/icons'

const allowedCodes = [
  'facebook',
  'instagram',
  'pinterest',
  'youtube'
]

export default {
  name: 'SocialMediaLinks',
  components: {
    InstagramIcon, PinterestIcon, YoutubeIcon, FacebookIcon
  },
  props: {
    content: {
      type: Array,
      required: true,
      default: () => ([])
    },
    linkClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    somes () {
      return Object.keys(this.content).length ? this.content.filter(item => allowedCodes.includes(item.code)) : []
    }
  }
}
