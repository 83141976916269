import Vue from 'vue'

type Position = 'bottom'|'topleft'
type Modifiers = { [key in Position]?: true }

const getPositionClass = (modifiers: Modifiers) => {
  const positions = {
    bottom: 'tooltip-bottom',
    topleft: 'tooltip-topleft'
  }
  return positions[Object.keys(modifiers)[0] as Position] || ''
}

Vue.directive('tooltip', {
  bind: (el: any, binding: any) => {
    if (binding.value) {
      el.classList.add('with-tooltip')
      const positionClass = getPositionClass(binding.modifiers)
      if (positionClass) {
        el.classList.add(positionClass)
      }
      el.setAttribute('data-tooltip', binding.value)
    }
  }
})
