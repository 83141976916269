
import Vue from 'vue'
import BaseButton from '~/components/BaseButton.vue'
import { XIcon, UserIcon, BadgePercentIcon } from '~/components/veke-ui/icons'
import ArrowLink from '~/components/site-header/mobile-menu/ArrowLink.vue'
import MenuItem from '~/components/site-header/mobile-menu/MenuItem.vue'
import SubMenu from '~/components/site-header/mobile-menu/SubMenu.vue'
import { CategoryGetters } from '~/store/category'
import { UiActions, UiGetters } from '~/store/ui'
import { UserGetters } from '~/store/user'

export default Vue.extend({
  name: 'SiteMenu',
  components: {
    SubMenu,
    BaseButton,
    MenuItem,
    XIcon,
    BadgePercentIcon,
    ArrowLink,
    UserIcon
  },
  data () {
    return {
      forward: true,
      history: []
    }
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters[UserGetters.loggedIn]
    },
    transition () {
      return this.forward ? 'full-slide-left' : 'full-slide-right'
    },
    categoryTree () {
      return this.$store.getters[CategoryGetters.getCategoryTree]
    },
    isMainMenuOpen () {
      return this.$store.getters[UiGetters.isMainMenuOpen]
    },
    isSubMenuOpen () {
      return this.isMainMenuOpen && this.history.length > 0
    }
  },
  watch: {
    '$route.path' () {
      // Close menu when route changes
      this.close()
    },
    isMainMenuOpen (isOpen) {
      // Scroll to top when menu is opened
      if (isOpen) {
        this.$nextTick(() => {
          this.$refs.mainmenu.scrollTo({ top: 0, left: 0 })
        })
      }
    }
  },
  beforeDestroy () {
    // Needed for layout changes
    this.close()
  },
  methods: {
    setCurrentMenu (cat) {
      this.forward = true
      if (this.$refs.submenu.$el.nodeName !== '#comment') {
        this.$refs.submenu.$el.scrollTo({
          top: 0,
          left: 0
        })
      }
      const categoryPath = '/' + cat.path
      if (cat.children?.length) {
        this.history.push(cat)
      } else if (this.$route.path === categoryPath) {
        this.close()
      } else {
        this.$router.push({ path: categoryPath })
      }
    },
    close () {
      this.history = []
      this.$store.dispatch(UiActions.closeMainMenu)
    },
    openSubmenu (cat) {
      this.forward = true
      this.history.push(cat)
    },
    back () {
      this.forward = false
      this.$refs.submenu.$el.scrollTo({
        top: 0,
        left: 0
      })
      this.history.pop()
    }
  }
})
