import { UserGetters } from '~/store/user'
import { useAuth } from '~/composable/useFirebase'

export default async function ({ app, next, store }: { app: any, next: any, store: any }) {
  if (process.client) {
    if (!store.getters[UserGetters.loggedIn]) {
      return next('/kirjaudu')
    }
    if (!app.$fire.auth.currentUser) {
      const { checkAuthStatus } = useAuth(app.$fire)
      const currentUser: any = await checkAuthStatus()
      if (!currentUser) {
        return next('/kirjaudu')
      }
      if (currentUser.emailVerified) {
        return next('/oma-tili')
      }
    }
  }
}
