/* eslint-disable no-console */
import type { Plugin } from '@nuxt/types'
import type { Matomo, MatomoOrder, MatomoItem, MatomoEcommView } from './types'

const options = JSON.parse('{"enabled":true,"matomoUrl":"https://matomo.veke.fi/","siteId":"1","debug":false,"pageViewTrackingEnabled":true,"ecommerceTracking":{"enabled":true,"cartUpdateTrackingEnabled":true,"productViewTrackingEnabled":true,"productPageUrlId":"/product/","categoryViewTrackingEnabled":true,"categoryPageUrlId":"/category/"}}')
const debugStyles = ['color:white;font-weight:bold;background:green;padding:4px 6px 2px 6px;border-radius:6px', 'color:black', 'font-style:italic']

const matomoPlugin: Plugin = (context, inject) => {
  const { app: { router /*, store */ } /* , route */ } = context
  let currentPath = ''

  // Private functions
  function _addEcommerceItem (items: MatomoItem[]) {
    let itemsTotal = 0

    items.forEach((item) => {
      itemsTotal += item.price * (item.amount || 1)
      window._paq.push(['addEcommerceItem', item.sku, item.name, item.categories, item.price, item.amount])
    })

    return itemsTotal
  }

  function _trackPageView (path: string) {
    const baseUrl = window.location.protocol + (window.location.protocol.slice(-1) === ':' ? '' : ':') + '//' + window.location.host

    window._paq.push(['setDocumentTitle', path])
    window._paq.push(['setCustomUrl', baseUrl + path])
    window._paq.push(['trackPageView'])
    window._paq.push(['setEcommerceView', null])
  }

  // Matomo-instance
  const matomo = (): Matomo => {
    return {
      /**
       * trackEcommerceCartUpdate
       * @param {*} items
       */
      trackEcommerceCartUpdate (items: MatomoItem[]) {
        if (process.client && window && window._paq && options.ecommerceTracking?.cartUpdateTrackingEnabled === true) {
          const grandTotal = _addEcommerceItem(items)
          window._paq.push(['trackEcommerceCartUpdate', grandTotal])

          if (options.debug === true) {
            console.debug('%cMATOMO' + '%c Cart Updated: ' + `%c${grandTotal}€`, debugStyles[0], debugStyles[1], debugStyles[2])
          }
        }
      },
      /**
       * trackEcommerceOrder
       * @param {*} order
       * @param {*} items
       */
      trackEcommerceOrder (order: MatomoOrder, items: MatomoItem[]) {
        if (process.client && window && window._paq) {
          const subTotal = _addEcommerceItem(items)
          const discount = order.discount ? Math.round((order.discount + Number.EPSILON) * 100) / 100 : 0

          const matomoOrder = [
            'trackEcommerceOrder',
            order.orderId, // (Required) orderId
            order.grandTotal, // (Required) grandTotal (revenue)
            subTotal, // (Optional) subTotal
            order.tax, // (optional) tax
            order.shippingPrice, // (optional) shipping
            discount // (optional) discount
          ]
          window._paq.push(matomoOrder)

          if (options.debug === true) {
            console.debug('%cMATOMO' + '%c Order Placed: ' + `%c${matomoOrder}`, debugStyles[0], debugStyles[1], debugStyles[2])
          }
        }
      },
      trackPageView (path: string) {
        if (process.client && window && window._paq) {
          let isDuplicatePageView = false
          if (options.ecommerceTracking?.enabled === true) {
            if ((options.ecommerceTracking?.productViewTrackingEnabled === true && path.includes(options.ecommerceTracking?.productPageUrlId)) ||
            (options.ecommerceTracking?.categoryViewTrackingEnabled === true && path.includes(options.ecommerceTracking?.categoryPageUrlId))) {
              isDuplicatePageView = true
            }
          }
          // Default page view tracking
          // Skipped on product/category pages if Ecomm Product/Category page view tracking is enabled
          if (options.pageViewTrackingEnabled === true && !isDuplicatePageView) {
            _trackPageView(path)

            if (options.debug === true) {
              console.debug('%cMATOMO' + '%c Track Page View: ' + `%c${path}`, debugStyles[0], debugStyles[1], debugStyles[2])
            }
          }
        }
      },
      /**
       * trackProductView
       * @param {*} productView
       * @param {*} path (optional)
       */
      trackProductView (productView: MatomoEcommView, path?: string) {
        if (process.client && window && window._paq && options.ecommerceTracking?.productViewTrackingEnabled === true) {
          const ecommView = [
            'setEcommerceView',
            productView.sku, // (Required) productSKU
            productView.name, // (Optional) productName // product.name.default
            productView.categories, // (Optional) categoryName
            productView.price // (Optional) price
          ]
          // Push Product View Data to Matomo
          window._paq.push(ecommView)
          // We must also call trackPageView when tracking a product view
          _trackPageView(path || currentPath)

          if (options.debug === true) {
            console.debug('%cMATOMO' + '%c Track PRODUCT Page View: ' + `%c[${JSON.stringify(ecommView)}]`, debugStyles[0], debugStyles[1], debugStyles[2])
          }
        }
      },
      /**
       * trackCategoryView
       * @param {*} breadcrumbs
       */
      trackCategoryView (categoryView: MatomoEcommView) {
        if (process.client && window && window._paq && options.ecommerceTracking?.categoryViewTrackingEnabled === true) {
          const ecommView = [
            'setEcommerceView',
            false, // Product SKU is not applicable for a category view.
            false, // Product name is not applicable for a category view.
            categoryView.categories // (Optional) categoryName
          ]
          // Push Category View Data to Matomo
          window._paq.push(ecommView)
          // We must also call trackPageView when tracking a category view
          _trackPageView(currentPath)

          if (options.debug === true) {
            console.debug('%cMATOMO' + '%c Track CATEGORY Page View: ' + `%c[${JSON.stringify(ecommView)}]`, debugStyles[0], debugStyles[1], debugStyles[2])
          }
        }
      },
      /**
       *
       * @param {*} category string
       * @param {*} action string
       * @param {*} name string (optional)
       * @param {*} value number (optional)
       */
      trackEvent (category: string, action: string, name?: string, value?: number) {
        if (process.client && window && window._paq) {
          window._paq.push(['trackEvent', category, action, name, value])

          if (options.debug === true) {
            console.debug('%cMATOMO' + '%c Track EVENT: ' + `%c[category: ${category}, action: ${action}, (name): ${name}, (value): ${value}]`, debugStyles[0], debugStyles[1], debugStyles[2])
          }
        }
      }
    }
  }

  context.$matomo = matomo()
  inject('matomo', context.$matomo)

  // Ran every time the route changes (fired on initialization too)
  if (router) {
    router.afterEach((to, _from) => {
      currentPath = to.path
      context.$matomo.trackPageView(currentPath)
    })
  }
}

export default matomoPlugin
