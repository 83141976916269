import { GetterTree, ActionTree, MutationTree } from 'vuex'
import type { ProductFilter, State, ProductSorter } from '~/types/productFilter'
import type { AisSortBy, AisUiIndexState, AisRouteState, AisUiState } from '~/types/instantsearch'
import { RootState } from '~/store'

const productFilters: ProductFilter[] = require('~/config/productFilters.json')
const productSorters: ProductSorter[] = require('~/config/productSorters.json')

const URL_VALUE_SEPARATOR = '~'

const rangeAttributeToRouteValue = function (state: any, attribute: string) {
  if (!state || !state.range || !state.range[attribute]) { return undefined }
  const prices = state.range[attribute].split(':')
  const [min, max] = prices
  return min + URL_VALUE_SEPARATOR + max
}
const toggleAttributeToRouteValue = function (state: any, attribute: string) {
  if (!state || !state.toggle || !state.toggle[attribute]) { return undefined }
  return state.toggle[attribute]
}
const attributeToRouteValue = function (state: any, attribute: string) {
  if (!state || !state.refinementList || !state.refinementList[attribute]) { return undefined }
  return state.refinementList[attribute].sort().join(URL_VALUE_SEPARATOR)
}
const routeParamToToggleValue = function (state: any, attribute: string) {
  return state[attribute] ? state[attribute] : undefined
}
const routeParamToRangeValue = function (state: any, attribute: string) {
  return state[attribute] ? state[attribute].replace(URL_VALUE_SEPARATOR, ':') : undefined
}
const routeParamToValue = function (state: any, attribute: string) {
  return state[attribute] ? state[attribute].split(URL_VALUE_SEPARATOR) : undefined
}

export const state = () => ({
  productFilters,
  productSorters,
  cachedState: null,
  cachedPath: null
})

export type ProductFilterState = State

export enum ProductFilterActions {
  saveCacheState = 'productFilter/saveCacheState'
}
export enum ProductFilterMutations {
  setCachedState = 'productFilter/SET_CACHED_STATE',
  setCachedPath = 'productFilter/SET_CACHED_PATH',
}
export enum ProductFilterGetters {
  getProductFilters = 'productFilter/getProductFilters',
  getProductFilterByAttributePath = 'productFilter/getProductFilterByAttributePath',
  getProductFiltersByCategoryPath = 'productFilter/getProductFiltersByCategoryPath',
  getProductSorters = 'productFilter/getProductSorters',
  getProductSortersInAisFormat = 'productFilter/getProductSortersInAisFormat',
  stateToRoute = 'productFilter/stateToRoute',
  routeToState = 'productFilter/routeToState',
  getCachedState = 'productFilter/getCachedState',
  getCache = 'productFilter/getCache'
}

export const actions: ActionTree<ProductFilterState, RootState> = {
  saveCacheState ({ commit }, payload: { fullPath: string, cache: any }) {
    commit('SET_CACHED_PATH', payload.fullPath)
    commit('SET_CACHED_STATE', payload.cache)
  }
}
export const mutations: MutationTree<ProductFilterState> = {
  SET_CACHED_STATE (state: ProductFilterState, payload: any) {
    // @ts-ignore
    state.cachedState = payload
  },
  SET_CACHED_PATH (state: ProductFilterState, payload: string) {
    state.cachedPath = payload
  }
}
export const getters: GetterTree<ProductFilterState, RootState> = {
  getCachedState: (state: State): any => (fullPath: string) => {
    return (state.cachedPath === fullPath) ? state.cachedState : null
  },
  getProductFilters: (state: State): ProductFilter[] => state.productFilters,
  getProductFilterByAttributePath: (state: State) => (path: string): ProductFilter | null => state.productFilters.find(f => f.attributePath === path) || null,
  getProductFiltersByCategoryPath: (state: State) => (categoryPath: string): ProductFilter[] | null =>
    state.productFilters.filter(f =>
      (!f.includeInPaths || (f.includeInPaths && f.includeInPaths?.some((inc: string) => categoryPath.startsWith(inc)))) &&
        (!f.excludeFromPaths || (f.excludeFromPaths && !f.excludeFromPaths?.some((exc: string) => categoryPath.startsWith(exc))))
    ),
  getProductSorters: (state: State): ProductSorter[] => state.productSorters,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getProductSortersInAisFormat: (state: State, rootState: RootState, rootGetters): AisSortBy[] => state.productSorters.map((s: ProductSorter) => ({ value: rootGetters['ALGOLIA_INDEX'] + s.indexAffix, label: s.label })),
  /**
   * Turns selected filters to an URL parameters
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stateToRoute: (state: State, getters, rootState: RootState, rootGetters) => (defaultSortByUrlValue = 'suosittelemme', routeQuery: { [key:string]: string } = {}) => (uiState: AisUiState): AisRouteState => {
    const productIndexName = rootGetters['ALGOLIA_INDEX']
    const indexUiState = uiState[productIndexName || '']
    const categoryJustChanged = !indexUiState?.configure
    // Add sorter, but clear it when category is switched (noticeable when routing from a category with a custom default sorter to a normal one, e.g. uutuudet -> huonekalut)
    const sortByIndex: string|undefined = !categoryJustChanged ? (indexUiState.sortBy || productIndexName) : undefined
    // Find URL value by index name
    const sortByUrlValue: string|undefined = state.productSorters.find((s: ProductSorter) => s.indexAffix === sortByIndex?.replace(productIndexName, ''))?.urlValue
    // Add sort parameter to URL, when non-default sorting is selected
    const sort: string|undefined = (defaultSortByUrlValue !== sortByUrlValue) ? sortByUrlValue : undefined
    // Add other url parameters (like utm code), but clear them when switching the category
    const routeState: AisRouteState = routeQuery && !categoryJustChanged ? { ...routeQuery } : {}

    state.productFilters.forEach((filter: ProductFilter) => {
      if (filter.type === 'range') {
        routeState[filter.urlParam] = rangeAttributeToRouteValue(indexUiState, filter.attributePath)
      } else if (filter.type === 'toggle') {
        routeState[filter.urlParam] = toggleAttributeToRouteValue(indexUiState, filter.attributePath)
      } else {
        routeState[filter.urlParam] = attributeToRouteValue(indexUiState, filter.attributePath)
      }
    })
    routeState.jarjesta = sort
    routeState.sivu = indexUiState?.page
    routeState.q = indexUiState?.query
    return JSON.parse(JSON.stringify(routeState))
  },
  /**
   * Turns URL parameters to selected filters
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  routeToState: (state: State, getters, rootState: RootState, rootGetters) => (defaultSortByUrlValue = 'suosittelemme') => (routeState: AisRouteState): AisUiState => {
    const range: any = {}
    const toggle: any = {}
    const productIndexName = rootGetters['ALGOLIA_INDEX']
    const defaultIndexAffix = state.productSorters.find((s: ProductSorter) => s.urlValue === defaultSortByUrlValue)?.indexAffix
    const indexAffix = state.productSorters.find((s: ProductSorter) => s.urlValue === routeState.jarjesta)?.indexAffix
    const sort: string|undefined = (indexAffix || defaultIndexAffix) ? productIndexName + (indexAffix || defaultIndexAffix) : undefined
    const refinementList: any = {}
    state.productFilters.forEach((filter: ProductFilter) => {
      if (filter.type === 'range') {
        const value = routeParamToRangeValue(routeState, filter.urlParam)
        if (value) {
          range[filter.attributePath] = value
        }
      } else if (filter.type === 'toggle') {
        const value = routeParamToToggleValue(routeState, filter.urlParam)
        if (value) {
          toggle[filter.attributePath] = value
        }
      } else {
        const value = routeParamToValue(routeState, filter.urlParam)
        if (value) {
          refinementList[filter.attributePath] = value
        }
      }
    })
    const fullUiIndexState: AisUiIndexState = { range, toggle, refinementList, sortBy: sort, page: routeState.sivu, query: routeState.q }
    /**
     * Remove keys with undefined or empty object as a value
     */
    const keys = Object.keys(fullUiIndexState) as Array<keyof typeof fullUiIndexState>
    const uiIndexState = keys.reduce<Record<string, string>>((newObj, key) => {
      if (fullUiIndexState[key] !== undefined && (typeof fullUiIndexState[key] !== 'object' || Object.keys(fullUiIndexState[key]).length > 0)) { newObj[key] = fullUiIndexState[key] }
      return newObj
    }, {})
    return {
      [productIndexName]: { ...uiIndexState }
    }
  },
  getCache: (state: State): any => state.cachedState
}
