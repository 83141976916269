import { Plugin } from '@nuxt/types'
import type { ICustomerService, ICustomerServiceItem } from '~/types/contentful/customer-service'
import type { IStore, IStoreItem, IStoreMin, IStoreItemMin } from '~/types/contentful/store'
import type { IFooter, IFooterItem } from '~/types/contentful/footer'
import type { IBanner, IBannerItem } from '~/types/contentful/banner'
import type { ICampaign, ICampaignItem } from '~/types/contentful/campaign'
import type { IGlobalMessage, IGlobalMessageItem } from '~/types/contentful/global-message'
import type { PageMessage, PageMessageItem } from '~/types/contentful/page-message'
import type { INewsletter, INewsletterItem } from '~/types/contentful/newsletter'
import type { IContentItem, IContent } from '~/types/contentful/content'

const importQueryFile = async (filename: string) => await import('~/queries/contentful/' + filename)

// @ts-ignore
const plugin: Plugin = ({ $contentful }, inject) => {
  inject('contentDb', {
    /*
     * Get content for footer
     */
    getFooter: async (): Promise<IFooterItem|undefined> => {
      const { queryFooterContent } = await importQueryFile('footer')
      const content: IFooter|undefined = await $contentful(queryFooterContent())
      return content?.footerCollection?.items?.[0]
    },
    /*
     *  Get all stores with a minimum information
     */
    getStoresMin: async (): Promise<IStoreItemMin[]|[]> => {
      const { queryStoresMin } = await importQueryFile('store')
      const content: IStoreMin|undefined = await $contentful(queryStoresMin())
      return content?.storeCollection?.items || []
    },
    /*
     *  Get a single store by type and slug
     */
    getStoreByTypeAndSlug: async (type = '', slug = ''): Promise<IStoreItem|undefined> => {
      const { queryStoreByTypeAndSlug } = await importQueryFile('store')
      const content: IStore|undefined = await $contentful(queryStoreByTypeAndSlug(type, slug))
      return content?.storeCollection?.items?.[0]
    },
    /*
     *  Get a single banner by slug
     */
    getBannerBySlug: async (slug = null): Promise<IBannerItem|undefined> => {
      const { queryBannerBySlug } = await importQueryFile('banner')
      const content: IBanner|undefined = await $contentful(queryBannerBySlug(slug))
      return content?.layoutHeroImageCollection?.items?.[0]
    },
    /*
     *  Get multiple banners by list of slugs
     */
    getBannersBySlugs: async (slugList = []): Promise<IBannerItem[]|undefined> => {
      const { queryBannersBySlugs } = await importQueryFile('banner')
      const content: IBanner|undefined = await $contentful(queryBannersBySlugs(slugList))
      return content?.layoutHeroImageCollection?.items
    },
    /*
     *  Get an active umbrella campaign
     */
    getUmbrellaCampaign: async (): Promise<ICampaignItem|undefined> => {
      const { queryUmbrellaCampaign } = await importQueryFile('campaign')
      const content: ICampaign|undefined = await $contentful(queryUmbrellaCampaign())
      return content?.campaignCollection?.items?.[0]
    },
    /*
     *  Get an active global message (AKA sticky notification)
     */
    getGlobalMessage: async (): Promise<IGlobalMessageItem|undefined> => {
      const { queryGlobalMessage } = await importQueryFile('global-message')
      const content: IGlobalMessage|undefined = await $contentful(queryGlobalMessage())
      return content?.globalMessageCollection?.items?.[0]
    },
    /*
     *  Get active page messages by location
     */
    getPageMessages: async (): Promise<PageMessageItem[]|undefined> => {
      const { queryPageMessages } = await importQueryFile('page-message')
      const content: PageMessage|undefined = await $contentful(queryPageMessages())
      return content?.pageMessageCollection?.items
    },
    /*
     *  Get newsletter sign up form content
     */
    getNewsletterSignUpContent: async (location: string): Promise<INewsletterItem|undefined> => {
      const { queryNewsletterSignUpContent } = await importQueryFile('newsletter')
      const content: INewsletter|undefined = await $contentful(queryNewsletterSignUpContent(location))
      return content?.newsletterSignUpCollection?.items?.[0]
    },
    /*
     *  Get customer service info: email, phone and openingTimes
     */
    getCustomerServiceInfo: async (): Promise<ICustomerServiceItem|undefined> => {
      const { queryCustomerServiceInfo } = await importQueryFile('customer-service')
      const content: ICustomerService|undefined = await $contentful(queryCustomerServiceInfo())
      return content?.customerServiceCollection?.items?.[0]
    },
    /*
     *  Get user profile by id
     */
    getUserProfileById: async (id: string): Promise<any> => {
      const { queryUserProfileById } = await importQueryFile('user-profile')
      const userProfile: any = await $contentful(queryUserProfileById(id))
      return userProfile?.userProfileCollection?.items?.[0]
    },
    /*
     *  Get all departments
     */
    getDepartments: async (): Promise<any> => {
      const { queryDepartments } = await importQueryFile('department')
      const departments: any = await $contentful(queryDepartments())
      return departments?.departmentCollection?.items || []
    },
    /*
     *  Get swatches by type
     */
    getSwatchesByType: async (type: string): Promise<any> => {
      const { querySwatchesByType } = await importQueryFile('swatch')
      const swatches: any = await $contentful(querySwatchesByType(type))
      return swatches?.swatchCollection?.items
    },
    /*
     *  Get category data
     */
    getCategoryData: async (urlPath: string): Promise<any> => {
      const { queryCategoryData } = await importQueryFile('category-data')
      const content: any = await $contentful(queryCategoryData(urlPath))
      return content?.categoryCollection?.items?.[0]
    },
    /*
     *  Get category data
     */
    getCategoriesContentful: async (): Promise<any> => {
      const { queryCategories } = await importQueryFile('categories')
      const content: any = await $contentful(queryCategories())
      return content
    },
    /*
     *  Get content by id
     */
    getContentById: async (id: string): Promise<IContentItem|undefined> => {
      const { queryContentById } = await importQueryFile('content')
      const content: IContent|undefined = await $contentful(queryContentById(id))
      return content?.contentCollection?.items?.[0]
    }
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $contentDb: any
  }
}
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $contentDb: any
  }
  interface Context {
    $contentDb: any
  }
}
declare module 'vuex/types/index' {
  interface Store<S> {
    $contentDb: S
  }
}

export default plugin
