export default function ({ app, route }: { app: any, route: any }) {
  if (process.client) {
    // Sends page page_visit-event to Bloom if cookie consent is given
    // Server side Bloomreach page_visit-events are handled by /static/bloomreach.js
    const excludedPages: string[] = ['/product/', '/ostoskori', '/kassa', '/kiitos']
    if (window && typeof window.exponea?.track === 'function' && window.localStorage?.getItem('cookieConsentGiven') === 'true') {
      // Send page visit-event to Bloomreach if not on an excluded page
      if (!excludedPages.some(page => route.fullPath.includes(page))) {
        window?.exponea?.track('page_visit', {
          location: app.$config.BASE_URL + route.fullPath,
          referrer: app.$config.BASE_URL,
          path: route.fullPath
        })
      }
    }
  }
}
