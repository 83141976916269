
import Vue from 'vue'
import ToastNotification from './ToastNotification.vue'
import { ToastGetters } from '~/store/toast'

export default Vue.extend({
  name: 'ToastNotifications',
  components: { ToastNotification },
  computed: {
    toasts (): string {
      return this.$store.getters[ToastGetters.toasts]
    }
  }
})
