
import { defineComponent } from '@nuxtjs/composition-api'
// @ts-ignore
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import { MARKS, BLOCKS } from '@contentful/rich-text-types'
import { XIcon } from '~/components/veke-ui/icons'
import Countdown from '~/components/global-message/Countdown.vue'
import type { IGlobalMessageItem } from '~/types/contentful/global-message'
import useRichText from '~/mixins/useRichText'

export default defineComponent({
  name: 'GlobalMessage',
  components: { RichTextRenderer, XIcon, Countdown },
  mixins: [useRichText],
  data () {
    return {
      globalMessage: null as IGlobalMessageItem|null,
      isVisible: true
    }
  },
  async fetch () {
    this.globalMessage = await this.$contentDb.getGlobalMessage()
  },
  computed: {
    markRenderers (): any {
      return ({
        [MARKS.BOLD]: (text: string, key: string, h: any) => h('strong', { key, attrs: { class: '!font-medium' } }, text)
      })
    },
    nodeRenderers (): any {
      return Object.assign({}, this.defaultNodeRenderers, {
        [BLOCKS.PARAGRAPH]: (node: any, key: string, h: any, next: any) => (
          h('span', { key }, next(node.content, key, h, next))
        )
      })
    }
  },
  mounted () {
    this.$emitter.on('global-message:open', (isOpen) => { this.isVisible = isOpen })
  },
  beforeDestroy () {
    this.$emitter.off('global-message:open')
  },
  methods: {
    close () {
      this.$emitter.emit('global-message:open', false)
    }
  }
})
