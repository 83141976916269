
import Vue from 'vue'
import SiteHeader from '~/components/site-header/SiteHeader.vue'
import SiteFooter from '~/components/site-footer/SiteFooter.vue'
import SiteMenu from '~/components/site-header/mobile-menu/SiteMenu.vue'
import Breadcrumbs from '~/components/Breadcrumbs.vue'
import GlobalMessage from '~/components/global-message/GlobalMessage.vue'
import ToastNotifications from '~/components/toast-notifications/ToastNotifications.vue'
import SkipToContent from '~/components/SkipToContent.vue'

export default Vue.extend({
  name: 'JoulukauppaLayout',
  components: {
    GlobalMessage,
    SiteHeader,
    SiteFooter,
    SiteMenu,
    Breadcrumbs,
    ToastNotifications,
    SkipToContent
  },
  head (): any {
    return {
      titleTemplate: (t: string) => {
        return `${t} - veke.fi`
      }
    }
  }
})
