import Vue from 'vue'
import type { AttributeValueObject, ProductImageVariants } from '~/types/product'
import { calculateDiscountPercent } from '~/helpers/price'
import { getMainImage, getVariationImageMasked, getVariationImage } from '~/helpers/product'

export default Vue.extend({
  computed: {
    isInOutlet (): boolean {
      // @ts-ignore
      return this.product.labels?.some(label => label.text === 'Outlet') || false
    },
    isSuperPrice (): boolean {
      // @ts-ignore
      return this.product.labels?.some(label => label.text === 'Superhinta') || false
    },
    isAlwaysAffordable () : boolean {
      // @ts-ignore
      return this.product.labels?.some(label => label.text === 'Aina edullinen') || false
    },
    isInSuperSale () : boolean {
      // @ts-ignore
      return this.product.labels?.some(label => label.text === 'SUPERTARJOUS') || false
    },
    productName () : String {
      // @ts-ignore
      return this.product.name?.default || this.product.name?.variant
    },
    lowestVariantFinalPrice () {
      // @ts-ignore
      return this.product?.variationPrices ? this.product?.variationPrices.lowest.finalPrice : this.product?.finalPrice?.inclTax
    },
    lowestVariantPrice () {
      // @ts-ignore
      return this.product?.variationPrices ? this.product?.variationPrices.lowest.price : this.product?.price?.inclTax
    },
    hasVariantPriceRange () {
      // @ts-ignore
      return this.product?.variationPrices && this.product.variationPrices.lowest.finalPrice !== this.product.variationPrices.highest.finalPrice
    },
    lowestVariantHasDiscount () : boolean {
      // @ts-ignore
      return this.lowestVariantFinalPrice < this.lowestVariantPrice
    },
    lowestVariantDiscountPercent (): number {
      // @ts-ignore
      return calculateDiscountPercent(this.lowestVariantPrice, this.lowestVariantFinalPrice)
    },
    hasDiscount () : boolean {
      // @ts-ignore
      return this.product.finalPrice.inclTax < this.product.price.inclTax
    },
    discountPercent (): number {
      // @ts-ignore
      return calculateDiscountPercent(this.product.price.inclTax, this.product.finalPrice.inclTax)
    },
    discountValidUntilText (): string {
      // @ts-ignore
      if (!this.product.finalPrice.endAt) { return '' }
      const weekdays = ['su', 'ma', 'ti', 'ke', 'to', 'pe', 'la']
      // @ts-ignore
      const validUntiDate = new Date(this.product.finalPrice.endAt)
      // Date.getMonth() returns month index starting from zero (January === 0)
      const dateStr = `${validUntiDate.getDate()}.${validUntiDate.getMonth() + 1}.`
      return `Tarjous voimassa <strong>vain ${weekdays[validUntiDate.getDay()]} ${dateStr} asti</strong>${this.isInSuperSale ? ', tai niin kauan kuin tuotetta riittää' : ''}`
    },
    schemaStockStatus (): string {
      // @ts-ignore
      switch (this.product?.stock?.stockStatus) {
        case 'inStock': return 'http://schema.org/InStock'
        case 'outOfStock': return 'http://schema.org/OutOfStock'
        default: return 'http://schema.org/BackOrder'
      }
    },
    salesPhrases (): string[] {
      const phrases = [
        // @ts-ignore
        this.product?.marketing?.productSalesPhrase1?.value,
        // @ts-ignore
        this.product?.marketing?.productSalesPhrase2?.value,
        // @ts-ignore
        this.product?.marketing?.productSalesPhrase3?.value
      ]
      return phrases.filter(Boolean)
    },
    currentOrNextSundayFormattedDate (): string {
      const now = new Date()
      now.setDate(now.getDate() + (0 + (7 - now.getDay())) % 7)
      return now.getDate() + '.' + (now.getMonth() + 1)
    }
  },
  methods: {
    getDynamicImageUrl (showMilieuImage: boolean, variant?: keyof ProductImageVariants): string {
      // @ts-ignore
      return (showMilieuImage ? getMainImage(this.product, variant) : getVariationImageMasked(this.product, variant)) || require('~/assets/images/placeholders/kuva-tulossa.svg')
    },
    getMainImageUrl (variant?: keyof ProductImageVariants): string {
      // @ts-ignore
      return getMainImage(this.product, variant) || require('~/assets/images/placeholders/kuva-tulossa.svg')
    },
    getMainVariationImageUrl (variant?: keyof ProductImageVariants): string {
      // @ts-ignore
      return getVariationImage(this.product, variant) || require('~/assets/images/placeholders/kuva-tulossa.svg')
    },
    getBrandList (): string {
      // @ts-ignore
      if (typeof this.product.marketing?.productManufacturer?.value === 'object') {
        // @ts-ignore
        return this.product.marketing?.productManufacturer?.value.map((brand: AttributeValueObject) => brand.label).join(', ')
      }
      return ''
    }
  }
})
