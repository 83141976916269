
import { defineComponent, computed, PropType } from '@nuxtjs/composition-api'
import type { ProductTileAlgolia } from '~/types/product'
import useProduct from '~/mixins/useProduct'
import ReviewStars from '~/components/product/ReviewStars.vue'

export default defineComponent({
  name: 'SearchItemProduct',
  components: { ReviewStars },
  mixins: [useProduct],
  props: {
    item: {
      type: Object as PropType<ProductTileAlgolia>,
      required: true
    }
  },
  emits: ['clear-search'],
  setup (props) {
    const reviewScore = computed(() => props.item?.yotpo?.reviewScore || 0)
    return { product: props.item, reviewScore }
  }
})
