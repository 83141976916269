import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { CategoryItem, State } from '~/types/category'
import { RootState } from '~/store'

export const state = () => ({
  categories: [] as CategoryItem[],
  categoryTree: null
})

export type CategoryState = State

export enum CategoryActions {
  fetchCategories = 'category/fetchCategories',
  fetchCategoryTree = 'category/fetchCategoryTree'
}

export enum CategoryMutations {
  setNavJson = 'category/SET_CATEGORIES',
}

export enum CategoryGetters {
  getCategoryById = 'category/getCategoryById',
  getCategoryByName = 'category/getCategoryByName',
  getCategoryByPath = 'category/getCategoryByPath',
  getSubCategoriesByPath = 'category/getSubCategoriesByPath',
  getMainCategories = 'category/getMainCategories',
  getCategoryTree = 'category/getCategoryTree',
  getPopularCategories = 'category/getPopularCategories',
  getPopularBrands = 'category/getPopularBrands'
}

export const actions: ActionTree<CategoryState, RootState> = {
  async fetchCategoryTree ({ commit }, payload): Promise<void> {
    await this.$axios.$get(`${payload}/category/categories-v2.json`)
      .then((categoryTree: any) => {
        commit('SET_CATEGORY_TREE', categoryTree.data)
      })
      .catch((error: any) => {
        // @TODO: Add Sentry logging
        console.error(error)
      })
  },
  async fetchCategories ({ commit }, payload): Promise<void> {
    await this.$axios.$get(`${payload}/category/category-list-v4.json`)
      .then((categories: any) => {
        commit('SET_CATEGORIES', categories.data)
      })
      .catch((error: any) => {
        // @TODO: Add Sentry logging
        console.error(error)
      })
  }
}

export const mutations: MutationTree<CategoryState> = {
  SET_CATEGORIES (state: State, payload: CategoryItem[]) {
    state.categories = payload
  },
  SET_CATEGORY_TREE (state: State, payload: any) {
    state.categoryTree = payload
  }
}

// @ts-ignore
export const getters: GetterTree<CategoryState, RootState> = {
  getCategoryByPath: (state: State) => (path: string) : CategoryItem|undefined => state.categories.find((c: CategoryItem) => c.path === path),
  getSubCategoriesByPath: (state: State) => (path: string) : CategoryItem[] => {
    const category = state.categories.find((c: CategoryItem) => c.path === path)
    return state.categories.filter(cat => category?.children.includes(cat.id))
  },
  getCategoryById: (state: State) => (id: string) : CategoryItem|undefined => state.categories.find((c: CategoryItem) => c.id === id),
  getCategoryByName: (state: State) => (name: string) : CategoryItem|undefined => state.categories.find((c: CategoryItem) => c.name === name),
  getMainCategories: (state: State) => state.categories.filter((c: CategoryItem) => c.level === 1 && c.isIncludedInMenu),
  getCategoryTree: (state: State): CategoryItem[] => state.categoryTree.slice().sort((a: CategoryItem, b: CategoryItem) => (a.sortOrder || 0) - (b.sortOrder || 0)),
  getPopularCategories: (state: State): CategoryItem[] => state.categories.filter((c: CategoryItem) => c.popular === true && !c.path?.startsWith('brandit/')),
  getPopularBrands: (state: State): CategoryItem[] => state.categories.filter((c: CategoryItem) => c.popular === true && c.path?.startsWith('brandit/'))
}
