const middleware = {}

middleware['account'] = require('../middleware/account.ts')
middleware['account'] = middleware['account'].default || middleware['account']

middleware['auth'] = require('../middleware/auth.ts')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['authCartMerge'] = require('../middleware/authCartMerge.ts')
middleware['authCartMerge'] = middleware['authCartMerge'].default || middleware['authCartMerge']

middleware['bloomreach'] = require('../middleware/bloomreach.ts')
middleware['bloomreach'] = middleware['bloomreach'].default || middleware['bloomreach']

middleware['gtm'] = require('../middleware/gtm.ts')
middleware['gtm'] = middleware['gtm'].default || middleware['gtm']

middleware['verifyEmail'] = require('../middleware/verifyEmail.ts')
middleware['verifyEmail'] = middleware['verifyEmail'].default || middleware['verifyEmail']

export default middleware
