import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { State, Breadcrumb } from '~/types/breadcrumb'
import { RootState } from '~/store'

export const state = () => ({
  current: []
})

export type BreadcrumbState = ReturnType<typeof state>

export enum BreadcrumbActions {
  setCurrentBreadcrumb = 'breadcrumb/setCurrentBreadcrumb'
}

export enum BreadcrumbMutations {
  setCurrentBreadcrumb = 'breadcrumb/SET_CURRENT_BREADCRUMB',
}

export enum BreadcrumbGetters {
  current = 'breadcrumb/current',
}

export const actions: ActionTree<BreadcrumbState, RootState> = {
  setCurrentBreadcrumb ({ commit }, payload: Breadcrumb[] | null) {
    commit('SET_CURRENT_BREADCRUMB', payload)
  }
}

export const mutations: MutationTree<BreadcrumbState> = {
  SET_CURRENT_BREADCRUMB (state: State, payload: Breadcrumb[]) {
    state.current = payload
  }
}

export const getters: GetterTree<BreadcrumbState, RootState> = {
  current: (state: State): Breadcrumb[] | null => state.current
}
