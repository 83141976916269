import type { CartItem } from '~/types/item'
import type { DeliveryMethod } from '~/types/checkout'

export function useShipping () {
  const getShippingCosts = (itemShippingCosts: string[]): DeliveryMethod[] => {
    const shippingCostsJson = require('../config/delivery-methods.json')
    const parsedShippingMethodsAndCosts: DeliveryMethod[] = []
    // Custom band-aid: Add general Placeholder shipping methods for Store pickup and Warehouse pickup
    let addPickUpFromTemplate = false
    const pickupTemplate: DeliveryMethod = {
      id: 'VekePickup',
      label: 'Nouto noutopisteeltä',
      price: 0,
      group: 'VekePickup',
      description: '5-10 arkipäivää'
    }
    itemShippingCosts.forEach((itemShippingCode: string) => {
      const parsedShipping = shippingCostsJson.find((cost : DeliveryMethod) => cost.id === itemShippingCode)
      if (parsedShipping) {
        // Custom band-aid: Check if pickup methods are allowed and does it contain warehouse or in-store methods
        if (parsedShipping.group === 'VekePickup') {
          addPickUpFromTemplate = false
        } else { // regular shipping methods
          parsedShippingMethodsAndCosts.push(parsedShipping)
        }
      }
    })
    if (addPickUpFromTemplate) {
      parsedShippingMethodsAndCosts.push(pickupTemplate)
    }
    parsedShippingMethodsAndCosts.sort((a, b) => a.price - b.price)
    return parsedShippingMethodsAndCosts
  }

  const getCartMaxShippingPrices = (cartItems: CartItem[]): DeliveryMethod[] => {
    let shippings: DeliveryMethod[] = []
    // loop all cart items to get shipping prices
    cartItems.forEach((item) => {
      if (shippings.length === 0) {
        shippings = Object.assign([], item.shipping)
      } else if (item.shipping.length > 0 && item.shipping[0].price > shippings[0].price) {
        // check both items lowest shipping price, replace if its bigger
        shippings = Object.assign([], item.shipping)
      }
    })
    // Sort ascending by shipping price, except if a zero cost shipping, the leave as last items
    return shippings.sort((a, b) => (a.price > b.price || a.price === 0) ? 1 : -1)
  }

  const getLowestMaxShippingPrice = (shippingCosts: DeliveryMethod[]): number => {
    const prices: number[] = []
    shippingCosts.forEach((shippingCost) => {
      // ignore zero priced shippings
      if (shippingCost.price > 0) {
        prices.push(shippingCost.price)
      }
    })
    return Math.min(...prices)
  }

  return {
    getShippingCosts,
    getCartMaxShippingPrices,
    getLowestMaxShippingPrice
  }
}
