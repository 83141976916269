
import { defineComponent } from '@nuxtjs/composition-api'
import { TruckIcon, FlagIcon, StarIcon, EuroIcon } from '~/components/veke-ui/icons'
export default defineComponent({
  name: 'BenefitBar',
  components: { TruckIcon, FlagIcon, StarIcon, EuroIcon },
  setup () {
    const benefits = [
      {
        icon: 'star-icon',
        label: 'Tyytyväiset asiakkaat'
      },
      {
        icon: 'truck-icon',
        label: 'Nopeat toimitukset'
      },
      {
        icon: 'euro-icon',
        label: 'Monipuoliset maksutavat'
      },
      {
        icon: 'flag-icon',
        label: 'Luotettava kotimainen yritys'
      }
    ]
    return { benefits }
  }
})
