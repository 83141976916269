import { ActionTree, GetterTree } from 'vuex'
import { CartActions } from '~/store/cart'
import { CategoryActions } from '~/store/category'

export const strict = false

export const state = () => ({
  ALGOLIA_INDEX: process.env.ALGOLIA_INDEX,
  ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID,
  ALGOLIA_API_KEY: process.env.ALGOLIA_API_KEY
})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  async nuxtServerInit ({ dispatch }, { $config }) {
    // dispatch(CartMutations.validCartCreatedAt)
    await dispatch(CategoryActions.fetchCategoryTree, $config.MEDIA_BUCKET_URL)
    await dispatch(CategoryActions.fetchCategories, $config.MEDIA_BUCKET_URL)
  },
  nuxtClientInit ({ dispatch }) {
    // At this point user context is available (functions know if customer is logged in or not)
    dispatch(CartActions.fetchCartRule)
  }
}

export const getters: GetterTree<RootState, RootState> = {
  ALGOLIA_INDEX: state => state.ALGOLIA_INDEX,
  ALGOLIA_APP_ID: state => state.ALGOLIA_APP_ID,
  ALGOLIA_API_KEY: state => state.ALGOLIA_API_KEY
}
