
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { ExternalLinkIcon } from '~/components/veke-ui/icons'

interface SearchItemLink {
  text: string
  strap?: string
  path?: string
  url?: string
}
export default defineComponent({
  name: 'SearchItemLink',
  components: { ExternalLinkIcon },
  props: {
    item: {
      type: Object as PropType<SearchItemLink>,
      required: true
    }
  },
  setup (props) {
    const comp = props.item.url ? { is: 'a', attrs: { href: props.item.url, target: '_blank' } } : { is: 'nuxt-link', attrs: { to: props.item.path } }
    return { comp }
  }
})
