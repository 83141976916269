
export default {
  name: 'BaseButton',
  props: {
    look: {
      type: String,
      required: false,
      default: 'primary',
      validator: prop => [
        'primary',
        'secondary',
        'tertiary'
      ].includes(prop)
    },
    transition: {
      type: String,
      required: false,
      default: 'cube-flip-2em'
    },
    state: {
      type: String,
      required: false,
      default: 'default'
    },
    to: {
      type: String,
      default: null,
      required: false
    },
    tag: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    states () {
      return Object.keys(this.$slots)
    },
    element () {
      return this.tag ? this.tag : this.$attrs.href ? 'a' : this.to ? 'nuxt-link' : 'button'
    },
    lookClass () {
      return this.look ? `btn-${this.look}` : ''
    },
    currentState () {
      return this.states.includes(this.state) ? this.state : 'default'
    },
    currentStateClass () {
      return `btn-state-${this.currentState}`
    },
    classes () {
      return ['btn', this.lookClass, this.currentStateClass]
    }
  },
  methods: {
    getStateKey (state) {
      return state + (this.currentState === state ? '_active' : '')
    },
    isCurrentState (state) {
      return this.currentState === state
    }
  }
}
