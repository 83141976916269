import { mapGtmPageType, getGtmUserDetails, getGtmPageTypeDetails } from '~/helpers/gtm'
import { useBreadcrumb } from '~/composable/useBreadcrumb'

export default function ({ app, route, store }: { app: any; route: any; store: any; }) {
  if (process.client) {
    const pageType = mapGtmPageType(route)
    // Skip category and search page. PageView-event implemented on the pages
    if (pageType !== 'category' && pageType !== 'searchResult') {
      app.$gtm.push({
        event: 'pageview',
        pageType, // category, product, searchResult, content, cart, etc...
        fullPath: route.fullPath,
        userDetails: getGtmUserDetails(store),
        ...getGtmPageTypeDetails(pageType, route.query)
      })
    }
  } else if (process.server) {
    app.$gtm.push({
      event: 'originalLocation',
      originalLocation: app.$config.BASE_URL + route.fullPath
    })
  }
  // Set breadbrumbs
  const { setBreadcrumbs } = useBreadcrumb()
  setBreadcrumbs(route, store)
}
